/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { Button, Stack } from "react-bootstrap";
import { css } from "@emotion/react";



export const Login: FC = memo(() => { 
    return (
        <div css={LoginWrapper}>
            <Stack gap={3} className="d-inline-flex">
                <h1 css={css`font-weight: 500;`}>Googleでログイン</h1>
                <Button variant="primary" size="lg">Googleでログインする</Button>
            </Stack>
        </div>
    );
});

const LoginWrapper = css`
    width: 96%;
    max-width: 1000px;
    margin: 0 auto;
`