import { FC, memo } from "react";
import { Button, Form, Stack } from "react-bootstrap";
import { SubTitle } from "../atoms/typography/SubTitle";
import { WrapperDefaultLayout } from "../template/WrapperDefaultLayout";


export const EmailTemplateEdit: FC = memo(() => { 
    return (
        <WrapperDefaultLayout>
            <SubTitle>メールテンプレート編集</SubTitle>
            <Stack direction="horizontal" gap={3}>
                <Button variant="primary">テンプレート1</Button>
                <Button variant="outline-primary">テンプレート2</Button>
                <Button variant="outline-primary">テンプレート3</Button>
                <Button variant="outline-primary">テンプレート4</Button>
                <Button variant="outline-primary">テンプレート5</Button>
            </Stack>
            <Form className="mt-4">
                <Form.Control as="textarea" rows={14} value="
                    名前 様
                    この文章はダミーです。ここにテンプレートの文章が入ります。
                    この文章はダミーです。ここにテンプレートの文章が入ります。
                    この文章はダミーです。ここにテンプレートの文章が入ります。
                ">
                </Form.Control>
                <Stack direction="horizontal" gap={3} className="mt-4 justify-content-center">
                    <Button variant="primary">保存する</Button>
                </Stack>
            </Form>
        </WrapperDefaultLayout>
    );
});