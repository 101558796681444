/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { Button, Stack } from "react-bootstrap";
import { css } from "@emotion/react";
import { WideFullPageLayout } from "../template/WideFullPageLayout";
import { SubTitle } from "../atoms/typography/SubTitle";
import { UserCard } from "../organisms/user/UserCard";
import { Link, Outlet } from "react-router-dom";


export const Edit: FC = memo(() => { 
    return (
        <WideFullPageLayout>
            <SubTitle>カード編集画面</SubTitle>
            <Stack direction="horizontal" gap={5} className="align-items-stretch" css={css`height: calc(100% - 5rem);`}>
                <section>
                    <UserCard />
                    <Stack direction="horizontal" gap={3} className="mt-3 justify-content-center">
                        <Link to="/edit/profile/"><Button variant="primary">基本情報を編集</Button></Link>
                        <Link to="/edit/comparison/"><Button variant="primary">見積内容を編集</Button></Link>
                        <Link to="/edit/sending/"><Button variant="outline-primary">メール送信</Button></Link>
                    </Stack>
                    <Stack direction="horizontal" className="mt-3 justify-content-center">
                        <Link to="/edit/"><Button variant="secondary">戻る</Button></Link>
                    </Stack>
                </section>
                <Outlet />
            </Stack>
        </WideFullPageLayout>
    );
});