import { FC, memo } from "react";
import { Button, Form, Stack } from "react-bootstrap";


export const EmailSending: FC = memo(() => { 
    return (
        <section className="flex-grow-1">
            <Stack direction="horizontal" gap={3} className="justify-content-start">
                <Button variant="primary">テンプレート1</Button>
                <Button variant="outline-primary">テンプレート2</Button>
                <Button variant="outline-primary">テンプレート3</Button>
                <Button variant="outline-primary">テンプレート4</Button>
                <Button variant="outline-primary">テンプレート5</Button>
            </Stack>
            <Form className="mt-4">
                <Form.Control as="textarea" rows={14} value="
                    名前 様
                    この文章はダミーです。ここにテンプレートの文章が入ります。
                    この文章はダミーです。ここにテンプレートの文章が入ります。
                    この文章はダミーです。ここにテンプレートの文章が入ります。
                ">
                </Form.Control>
                <Stack direction="horizontal" gap={3} className="mt-4 justify-content-center">
                    <Button variant="primary">送信する</Button>
                    <Button variant="secondary">キャンセル</Button>
                </Stack>
            </Form>
        </section>
    );
});