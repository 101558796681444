/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { Stack } from "react-bootstrap";
import { css } from "@emotion/react";
import { SubTitle } from "../atoms/typography/SubTitle";
import { HistoryChild } from "../atoms/HistoryChild";
import { WrapperDefaultLayout } from "../template/WrapperDefaultLayout";
import { UserCard } from "../organisms/user/UserCard";


export const CardChangeLog: FC = memo(() => { 
    return (
        <WrapperDefaultLayout>
            <SubTitle>カード変更履歴画面</SubTitle>
            <Stack direction="horizontal" gap={5} className="align-items-stretch" css={css`height: calc(100% - 5rem);`}>
                <section>
                    <UserCard height="100%"/>
                </section>
                <section>
                    <HistoryChild />
                </section>
            </Stack>
        </WrapperDefaultLayout>
    );
});