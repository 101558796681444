/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FC, memo } from "react";
import { Button, Card, Col, Form, InputGroup, ListGroup, Pagination, Row, Stack } from "react-bootstrap";
import { Link } from "react-router-dom";
import { UserCard } from "../organisms/user/UserCard";
import { WideFullPageLayout } from "../template/WideFullPageLayout";

export const Home: FC = memo(() => {
    return (
        <WideFullPageLayout>
            <Stack direction="horizontal" gap={5} className="py-5 align-items-stretch" css={css`height: 100%;`}>
                <section className="pe-4">
                    <Form css={css`width: 270px;`}>
                        <fieldset>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="textInputName">氏名</Form.Label>
                                <Form.Control id="textInputName" />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="textInputMail">メールアドレス</Form.Label>
                                <Form.Control id="textInputMail" />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="textInputTel">電話番号</Form.Label>
                                <Form.Control id="textInputTel" />
                            </Form.Group>
                            <Form.Group className="mb-5">
                                <Form.Label htmlFor="textInputId">ID</Form.Label>
                                <Form.Control id="textInputId" />
                            </Form.Group>
                            <Form.Group className="mb-5">
                                <Form.Label htmlFor="textInputFromDate">診断日</Form.Label>
                                <Form.Control id="textInputFromDate" className="mb-3" />
                                <Form.Label htmlFor="textInputUntilDate">〜</Form.Label>
                                <Form.Control id="textInputUntilDate" />
                            </Form.Group>
                            <Form.Group className="mb-5">
                                <Form.Label>連絡先</Form.Label>
                                <InputGroup className="mb-3">
                                    <InputGroup.Checkbox />
                                    <Form.Control as="label">あり</Form.Control>
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Checkbox />
                                    <Form.Control as="label">なし</Form.Control>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group className="mb-5">
                                <Form.Label>加入者</Form.Label>
                                <InputGroup className="mb-3">
                                    <InputGroup.Checkbox />
                                    <Form.Control as="label">本人</Form.Control>
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Checkbox />
                                    <Form.Control as="label">回答者の夫</Form.Control>
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Checkbox />
                                    <Form.Control as="label">回答者の妻</Form.Control>
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Checkbox />
                                    <Form.Control as="label">その他</Form.Control>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group className="mb-5">
                                <Form.Label>提携先</Form.Label>
                                <InputGroup className="mb-3">
                                    <InputGroup.Checkbox />
                                    <Form.Control as="label">サイト</Form.Control>
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Checkbox />
                                    <Form.Control as="label">みんかぶ</Form.Control>
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Checkbox />
                                    <Form.Control as="label">広告</Form.Control>
                                </InputGroup>
                            </Form.Group>
                            <div className="text-center">
                                <Button variant="primary" type="submit">検索</Button>
                            </div>
                        </fieldset>
                    </Form>
                </section>
                <section className="pe-4">
                    <Stack direction="horizontal" gap={2} className="justify-content-end">
                        <Button variant="success">新規登録</Button>
                    </Stack>
                    <div className="mt-5" css={css`width: 500px;`}>
                        <p>2023年9月30日（金）</p>
                        <ListGroup>
                            <ListGroup.Item className="py-2">
                                <Stack direction="horizontal" gap={2} className="justify-content-between">
                                    <p className="m-0">ダミーダミー</p>
                                    <Stack direction="horizontal" gap={2} className="align-items-center">
                                        <p className="m-0">2023/09/30 14:45</p>
                                        <Button size="sm" variant="success" className="rounded-pill py-0 px-2 fw-bold" css={css`font-size:12px;`}>プレビュー</Button>
                                    </Stack>
                                </Stack>
                            </ListGroup.Item>
                            <ListGroup.Item className="py-2">
                                <Stack direction="horizontal" gap={2} className="justify-content-between">
                                    <p className="m-0">ダミーダミー</p>
                                    <Stack direction="horizontal" gap={2} className="align-items-center">
                                        <p className="m-0">2023/09/30 14:45</p>
                                        <Button size="sm" variant="success" className="rounded-pill py-0 px-2 fw-bold" css={css`font-size:12px;`}>プレビュー</Button>
                                    </Stack>
                                </Stack>
                            </ListGroup.Item>
                            <ListGroup.Item className="py-2">
                                <Stack direction="horizontal" gap={2} className="justify-content-between">
                                    <p className="m-0">ダミーダミー</p>
                                    <Stack direction="horizontal" gap={2} className="align-items-center">
                                        <p className="m-0">2023/09/30 14:45</p>
                                        <Button size="sm" variant="success" className="rounded-pill py-0 px-2 fw-bold" css={css`font-size:12px;`}>プレビュー</Button>
                                    </Stack>
                                </Stack>
                            </ListGroup.Item>
                            <ListGroup.Item className="py-2">
                                <Stack direction="horizontal" gap={2} className="justify-content-between">
                                    <p className="m-0">ダミーダミー</p>
                                    <Stack direction="horizontal" gap={2} className="align-items-center">
                                        <p className="m-0">2023/09/30 14:45</p>
                                        <Button size="sm" variant="success" className="rounded-pill py-0 px-2 fw-bold" css={css`font-size:12px;`}>プレビュー</Button>
                                    </Stack>
                                </Stack>
                            </ListGroup.Item>
                        </ListGroup>

                    </div>
                    <div className="mt-5" css={css`width: 500px;`}>
                        <p>2023年9月30日（金）</p>
                        <ListGroup>
                            <ListGroup.Item className="py-2">
                                <Stack direction="horizontal" gap={2} className="justify-content-between">
                                    <p className="m-0">ダミーダミー</p>
                                    <Stack direction="horizontal" gap={2} className="align-items-center">
                                        <p className="m-0">2023/09/30 14:45</p>
                                        <Button size="sm" variant="success" className="rounded-pill py-0 px-2 fw-bold" css={css`font-size:12px;`}>プレビュー</Button>
                                    </Stack>
                                </Stack>
                            </ListGroup.Item>
                            <ListGroup.Item className="py-2">
                                <Stack direction="horizontal" gap={2} className="justify-content-between">
                                    <p className="m-0">ダミーダミー</p>
                                    <Stack direction="horizontal" gap={2} className="align-items-center">
                                        <p className="m-0">2023/09/30 14:45</p>
                                        <Button size="sm" variant="success" className="rounded-pill py-0 px-2 fw-bold" css={css`font-size:12px;`}>プレビュー</Button>
                                    </Stack>
                                </Stack>
                            </ListGroup.Item>
                            <ListGroup.Item className="py-2">
                                <Stack direction="horizontal" gap={2} className="justify-content-between">
                                    <p className="m-0">ダミーダミー</p>
                                    <Stack direction="horizontal" gap={2} className="align-items-center">
                                        <p className="m-0">2023/09/30 14:45</p>
                                        <Button size="sm" variant="success" className="rounded-pill py-0 px-2 fw-bold" css={css`font-size:12px;`}>プレビュー</Button>
                                    </Stack>
                                </Stack>
                            </ListGroup.Item>
                            <ListGroup.Item className="py-2">
                                <Stack direction="horizontal" gap={2} className="justify-content-between">
                                    <p className="m-0">ダミーダミー</p>
                                    <Stack direction="horizontal" gap={2} className="align-items-center">
                                        <p className="m-0">2023/09/30 14:45</p>
                                        <Button size="sm" variant="success" className="rounded-pill py-0 px-2 fw-bold" css={css`font-size:12px;`}>プレビュー</Button>
                                    </Stack>
                                </Stack>
                            </ListGroup.Item>
                        </ListGroup>

                        <Pagination className="mt-5 mb-0 justify-content-center">
                            <Pagination.Prev disabled>Previous</Pagination.Prev>
                            <Pagination.Item active>{1}</Pagination.Item>
                            <Pagination.Item>{2}</Pagination.Item>
                            <Pagination.Item>{3}</Pagination.Item>
                            <Pagination.Next>Next</Pagination.Next>
                        </Pagination>
                    </div>

                </section>
                <section>
                    <UserCard />
                    <Stack direction="horizontal" gap={3} className="mt-4 justify-content-center">
                        <Link to="/edit/"><Button variant="primary">編集</Button></Link>
                        <Link to="/log/"><Button variant="outline-primary">カード変更履歴</Button></Link>
                        <Button variant="secondary">削除する</Button>
                    </Stack>
                </section>
                <section className="pe-4">
                    <p>ユーザー画面プレビュー</p>
                    <Card className="py-3 px-2 border-none" css={userAppPreview}>
                        <Card.Body>
                            <p className="small fw-bold my-0" css={previewPrimaryColor}>必要保障額は…</p>
                            <p css={[previewTextXxs, previewSecondaryColor]}>用意しておくべき保険金の目安</p>
                            <div>
                                <p style={{ color: 'red', padding: "80px 40px", textAlign: "center" }}>グラフが入ります</p>
                            </div>
                            <div className="text-end">
                                <div>
                                    <p className="small fw-bold my-0" css={previewSecondaryColor}>もし今年で亡くなったら…</p>
                                    <p className="lh-1"><span className="fs-2 m-0" css={[previewNumberFont, previewPrimaryColor]}>200万</span><b>円</b></p>
                                </div>
                                <div>
                                    <p className="small fw-bold my-0" css={previewSecondaryColor}>もし40歳で亡くなったら…</p>
                                    <p className="lh-1"><span className="fs-2 m-0" css={[previewNumberFont, previewPrimaryColor]}>200万</span><b>円</b></p>
                                </div>
                                <div >
                                    <p className="small fw-bold my-0" css={previewSecondaryColor}>もし55歳で亡くなったら…</p>
                                    <p className="lh-1"><span className="fs-2 m-0" css={[previewNumberFont, previewPrimaryColor]}>200万</span><b>円</b></p>
                                </div>
                            </div>
                            <p className="my-0" css={[previewTextXxs, previewSecondaryColor]}>保険加入者のかたに万が一のこと（死亡）があった場合、大きなまとまったお金が必要になります。</p>
                        </Card.Body>
                    </Card>
                    <Card className="py-3 px-2 mt-4 border-none" css={userAppPreview}>
                        <Card.Body>
                            <p className="small fw-bold my-0" css={previewPrimaryColor}>年間保険料</p>
                            <p css={[previewTextXxs, previewSecondaryColor]}>もし保険に加入した場合、年間でお支払いいただく金額</p>
                            <div className="text-end">
                                <p className="lh-1 m-0"><b>年間</b><span className="fs-2" css={[previewNumberFont, previewPrimaryColor]}>88,560</span><b>円</b></p>
                                <p className="small fw-bold my-0">で必要保障が準備可能です！</p>
                            </div>
                        </Card.Body>
                    </Card>

                    <Card className="py-3 px-2 mt-4 border-none" css={userAppPreview}>
                        <Card.Body>
                            <p className="small fw-bold my-0" css={previewPrimaryColor}>見積もり</p>
                            <p css={[previewTextXxs, previewSecondaryColor]}>見直した場合の、月々の保険料見込み</p>
                            <div>
                                <ul css={[recommendedBody,css`font-size: 12px;`]} className="p-0">
                                    <li css={css`display:grid; grid-template-columns:repeat(2,1fr);`}>
                                        <div css={recommendedInsuranceItems_child} className="justify-content-between align-items-center">
                                            <div>
                                                <div style={{ width: "100px", height: "30px", boxShadow: "0 0 4px #ccc", borderRadius: "5px" }}></div>
                                                <p css={[previewSecondaryColor,css`font-size: 10px;`]} className="m-0">アイウエオあいう生命</p>
                                            </div>
                                            <div style={{flexShrink:0}}>
                                                <div css={[previewPrimaryColor, css`line-height:1.3;`]}>
                                                    <div>
                                                        <div style={{ fontSize: "13px" }}>死亡保険(定期)</div>
                                                    </div>
                                                    <div>
                                                        <div>保障金額：1,000万円</div>
                                                    </div>
                                                    <div>
                                                        <div>保険期間：終身</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div css={recommendedInsuranceItems_child} className="align-items-center justify-content-end">
                                            <div className='text-center'><span css={[recommendedInsuranceItems_number]}>2,460</span>円</div>
                                            <div className='text-center' css={css`flex-shrink: 0;`}>
                                                <Button variant='outline-primary' className="rounded-pill m-auto ps-4 pe-4 gap-1 align-items-center" style={{ fontSize: "11px" }}>商品を見る</Button>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div css={[previewNumberFont, previewPrimaryColor]} className="border-top border-dark border-2 pt-3 d-flex justify-content-around align-items-end fw-bold">
                                <span>月々合計</span>
                                <span><span css={css`font-size: 24px;`}>3,000</span>円</span>
                            </div>
                        </Card.Body>
                    </Card>
                </section>
            </Stack>
        </WideFullPageLayout>
    );
});

const userAppPreview = css`
    width: 475px;
    font-family: Hiragino Kaku Gothic Pro,Helvetica Neue,Arial,Hiragino Kaku Gothic ProN,Hiragino Sans,Meiryo,sans-serif;
`
const previewNumberFont = css`
    font-family: Number Font,Hiragino Kaku Gothic Pro,Helvetica Neue,Arial,Hiragino Kaku Gothic ProN,Hiragino Sans,Meiryo,sans-serif;
    font-weight: 500;
`

const previewPrimaryColor = css`
    color: #00153e;
`
const previewSecondaryColor = css`
    color: #5e5e5e;
`
const previewTextXxs = css`
    font-size: 0.75rem;
`
const recommendedBody = css`
    display:grid;
    grid-template-columns:1fr;
    row-gap:15px;
    margin:33px 0 24px;
`

const recommendedInsuranceItems_child = css`
	display:flex;
	column-gap: 10px;
`
const recommendedInsuranceItems_number = css`
	font-size: 20px;
	font-weight: 500;
	margin-right: 4px;
`
