import { FC, memo } from "react";
import { Route, Routes } from "react-router-dom";
import { Login } from "../components/pages/Login";
import { Home } from "../components/pages/Home";
import { Edit } from "../components/pages/Edit";
import { EmailSending } from "../components/pages/EmailSending";
import { EmailTemplateEdit } from "../components/pages/EmailTemplateEdit";
import { ProductInfoEdit } from "../components/pages/ProductInfoEdit";
import { ProductSearch } from "../components/pages/ProductSearch";
import { PartnerSetting } from "../components/pages/PartnerSetting";
import { AccountControl } from "../components/pages/AccountControl";
import { ManagementList } from "../components/pages/ManagementList";
import { ProfileEdit } from "../components/pages/ProfileEdit";
import { ComparisonEdit } from "../components/pages/ComparisonEdit";
import { CardChangeLog } from "../components/pages/CardChangeLog";
import { DataImport } from "../components/pages/DataImport";


export const Router: FC = memo(() => {
    return (
        <Routes>
            <Route path="/" element={<Login />}/>
            <Route path="/home" element={<Home />}/>
            <Route path="/edit" element={<Edit />}>
                <Route path="sending" element={<EmailSending />}/>
                <Route path="profile" element={<ProfileEdit />}/>
                <Route path="comparison" element={<ComparisonEdit />}/>
            </Route>
            <Route path="/log" element={<CardChangeLog />}/>
            <Route path="/email" element={<EmailTemplateEdit />}></Route>
            <Route path="/product" element={<ProductInfoEdit />}></Route>
            <Route path="/import" element={<DataImport />}></Route>
            <Route path="/search" element={<ProductSearch />}></Route>
            <Route path="/partner" element={<PartnerSetting />}></Route>
            <Route path="/account" element={<AccountControl />}></Route>
            <Route path="/management" element={<ManagementList />}></Route>
        </Routes>
    )
});