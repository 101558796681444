/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FC, memo, ReactNode } from "react";
import { Header } from "../atoms/Header";

type Props = {
    children: ReactNode;
}

export const WrapperDefaultLayout: FC<Props> = memo((props) => {
    const { children } = props;
    return (
        <>        
            <Header />
            <div css={wrapper}>
                { children }
            </div>
        </>
    );
}) ;

const wrapper = css`
    width: 96%;
    max-width: 1000px;
    margin: 0 auto;
    padding-bottom: 40px;
`