/**  @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { Accordion, Container, Row, Col, Button, Stack, ListGroup } from "react-bootstrap";
import { css } from "@emotion/react";

const accordionHeaderColor = css`
  & > button{
    background-color: #0d6efd !important;
    color: #fff !important;
    &:after{
      color: #fff !important;
      filter: grayscale(1) brightness(10) !important;
    }
  }
`

export const HistoryChild: FC = memo(() => {
	return(	
		<Accordion defaultActiveKey="0" css={css`width: 500px;`}>
			<Accordion.Item eventKey="0" className="mb-3">
				<Accordion.Header css={accordionHeaderColor}>2023.01.01 IDが入ります</Accordion.Header>
				<Accordion.Body className="pt-3 px-0 pb-0">
					<Container>
						<Stack direction="horizontal" gap={2} className="mt-2 mb-3">
							<Button variant="secondary" size="sm" active>資料請求</Button>
							<Button variant="secondary" size="sm" disabled>LINE</Button>
							<Button variant="secondary" size="sm" disabled>SMS</Button>
							<Button variant="secondary" size="sm" disabled>メール</Button>
							<Button variant="secondary" size="sm" disabled>FPに相談</Button>
						</Stack>
						<p>本人の保険を見直したい/年間69,264円/節約額-530,736円/提案保険料69,264円</p>
					</Container>
					<ListGroup variant="flush" className="border-top-0">
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">電話番号</Col>
								<Col bsPrefix="col-8">00000000000</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">メールアドレス</Col>
								<Col bsPrefix="col-8">0000@.com</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">氏名</Col>
								<Col bsPrefix="col-8">山田太朗</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">カナ氏名</Col>
								<Col bsPrefix="col-8">ヤマダタロウ</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">保険契約者</Col>
								<Col bsPrefix="col-8 fw-bold">本人</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">性別</Col>
								<Col bsPrefix="col-8">男性</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">子供</Col>
								<Col bsPrefix="col-8">0人</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">子供の年齢</Col>
								<Col bsPrefix="col-8">1人目10歳/2人目5歳</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">去年の年収</Col>
								<Col bsPrefix="col-8">500万人</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">月々の保険料</Col>
								<Col bsPrefix="col-8">2万円</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">3大疾病</Col>
								<Col bsPrefix="col-8">経験なし</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">喫煙</Col>
								<Col bsPrefix="col-8">月50,000円（年600,000円）</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">再/精密検査</Col>
								<Col bsPrefix="col-8">なし</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">持ち家の形態</Col>
								<Col bsPrefix="col-8">持ち家</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">配偶者の年収</Col>
								<Col bsPrefix="col-8">500万円</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">保険会社</Col>
								<Col bsPrefix="col-8">アクサ生命</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">加入期間</Col>
								<Col bsPrefix="col-8">60</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">保証内容</Col>
								<Col bsPrefix="col-8">終身保険/収入保障保険/がん保険</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">提供元</Col>
								<Col bsPrefix="col-8">みんかぶ</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">流入元</Col>
								<Col bsPrefix="col-8">min-koukoku1</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item className="pt-4">
							<Row>
								<Col bsPrefix="col-4">提案一つ目</Col>
								<Col bsPrefix="col-8">月2,802円</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">保険会社</Col>
								<Col bsPrefix="col-8">オリックス生命</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">商品名</Col>
								<Col bsPrefix="col-8">終身保険</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">保証内容</Col>
								<Col bsPrefix="col-8">終身保険/収入保障保険/がん保険</Col>
							</Row>
						</ListGroup.Item>
					</ListGroup>
				</Accordion.Body>
			</Accordion.Item>
			
			<Accordion.Item eventKey="1" className="mb-3">
				<Accordion.Header css={accordionHeaderColor}>2023.01.01 IDが入ります</Accordion.Header>
				<Accordion.Body className="pt-3 px-0 pb-0">
					<Container>
						<Stack direction="horizontal" gap={2} className="mt-2 mb-3">
							<Button variant="secondary" size="sm" active>資料請求</Button>
							<Button variant="secondary" size="sm" disabled>LINE</Button>
							<Button variant="secondary" size="sm" disabled>SMS</Button>
							<Button variant="secondary" size="sm" disabled>メール</Button>
							<Button variant="secondary" size="sm" disabled>FPに相談</Button>
						</Stack>
						<p>本人の保険を見直したい/年間69,264円/節約額-530,736円/提案保険料69,264円</p>
					</Container>
					<ListGroup variant="flush" className="border-top-0">
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">電話番号</Col>
								<Col bsPrefix="col-8">00000000000</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">メールアドレス</Col>
								<Col bsPrefix="col-8">0000@.com</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">氏名</Col>
								<Col bsPrefix="col-8">山田太朗</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">カナ氏名</Col>
								<Col bsPrefix="col-8">ヤマダタロウ</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">保険契約者</Col>
								<Col bsPrefix="col-8 fw-bold">本人</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">性別</Col>
								<Col bsPrefix="col-8">男性</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">子供</Col>
								<Col bsPrefix="col-8">0人</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">子供の年齢</Col>
								<Col bsPrefix="col-8">1人目10歳/2人目5歳</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">去年の年収</Col>
								<Col bsPrefix="col-8">500万人</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">月々の保険料</Col>
								<Col bsPrefix="col-8">2万円</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">3大疾病</Col>
								<Col bsPrefix="col-8">経験なし</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">喫煙</Col>
								<Col bsPrefix="col-8">月50,000円（年600,000円）</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">再/精密検査</Col>
								<Col bsPrefix="col-8">なし</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">持ち家の形態</Col>
								<Col bsPrefix="col-8">持ち家</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">配偶者の年収</Col>
								<Col bsPrefix="col-8">500万円</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">保険会社</Col>
								<Col bsPrefix="col-8">アクサ生命</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">加入期間</Col>
								<Col bsPrefix="col-8">60</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">保証内容</Col>
								<Col bsPrefix="col-8">終身保険/収入保障保険/がん保険</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">提供元</Col>
								<Col bsPrefix="col-8">みんかぶ</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">流入元</Col>
								<Col bsPrefix="col-8">min-koukoku1</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item className="pt-4">
							<Row>
								<Col bsPrefix="col-4">提案一つ目</Col>
								<Col bsPrefix="col-8">月2,802円</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">保険会社</Col>
								<Col bsPrefix="col-8">オリックス生命</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">商品名</Col>
								<Col bsPrefix="col-8">終身保険</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">保証内容</Col>
								<Col bsPrefix="col-8">終身保険/収入保障保険/がん保険</Col>
							</Row>
						</ListGroup.Item>
					</ListGroup>
				</Accordion.Body>
			</Accordion.Item>
			
			<Accordion.Item eventKey="2" className="mb-3">
				<Accordion.Header css={accordionHeaderColor}>2023.01.01 IDが入ります</Accordion.Header>
				<Accordion.Body className="pt-3 px-0 pb-0">
					<Container>
						<Stack direction="horizontal" gap={2} className="mt-2 mb-3">
							<Button variant="secondary" size="sm" active>資料請求</Button>
							<Button variant="secondary" size="sm" disabled>LINE</Button>
							<Button variant="secondary" size="sm" disabled>SMS</Button>
							<Button variant="secondary" size="sm" disabled>メール</Button>
							<Button variant="secondary" size="sm" disabled>FPに相談</Button>
						</Stack>
						<p>本人の保険を見直したい/年間69,264円/節約額-530,736円/提案保険料69,264円</p>
					</Container>
					<ListGroup variant="flush" className="border-top-0">
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">電話番号</Col>
								<Col bsPrefix="col-8">00000000000</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">メールアドレス</Col>
								<Col bsPrefix="col-8">0000@.com</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">氏名</Col>
								<Col bsPrefix="col-8">山田太朗</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">カナ氏名</Col>
								<Col bsPrefix="col-8">ヤマダタロウ</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">保険契約者</Col>
								<Col bsPrefix="col-8 fw-bold">本人</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">性別</Col>
								<Col bsPrefix="col-8">男性</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">子供</Col>
								<Col bsPrefix="col-8">0人</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">子供の年齢</Col>
								<Col bsPrefix="col-8">1人目10歳/2人目5歳</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">去年の年収</Col>
								<Col bsPrefix="col-8">500万人</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">月々の保険料</Col>
								<Col bsPrefix="col-8">2万円</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">3大疾病</Col>
								<Col bsPrefix="col-8">経験なし</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">喫煙</Col>
								<Col bsPrefix="col-8">月50,000円（年600,000円）</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">再/精密検査</Col>
								<Col bsPrefix="col-8">なし</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">持ち家の形態</Col>
								<Col bsPrefix="col-8">持ち家</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">配偶者の年収</Col>
								<Col bsPrefix="col-8">500万円</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">保険会社</Col>
								<Col bsPrefix="col-8">アクサ生命</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">加入期間</Col>
								<Col bsPrefix="col-8">60</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">保証内容</Col>
								<Col bsPrefix="col-8">終身保険/収入保障保険/がん保険</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">提供元</Col>
								<Col bsPrefix="col-8">みんかぶ</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">流入元</Col>
								<Col bsPrefix="col-8">min-koukoku1</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item className="pt-4">
							<Row>
								<Col bsPrefix="col-4">提案一つ目</Col>
								<Col bsPrefix="col-8">月2,802円</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">保険会社</Col>
								<Col bsPrefix="col-8">オリックス生命</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">商品名</Col>
								<Col bsPrefix="col-8">終身保険</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">保証内容</Col>
								<Col bsPrefix="col-8">終身保険/収入保障保険/がん保険</Col>
							</Row>
						</ListGroup.Item>
					</ListGroup>
				</Accordion.Body>
			</Accordion.Item>
			
			<Accordion.Item eventKey="3" className="mb-3">
				<Accordion.Header css={accordionHeaderColor}>2023.01.01 IDが入ります</Accordion.Header>
				<Accordion.Body className="pt-3 px-0 pb-0">
					<Container>
						<Stack direction="horizontal" gap={2} className="mt-2 mb-3">
							<Button variant="secondary" size="sm" active>資料請求</Button>
							<Button variant="secondary" size="sm" disabled>LINE</Button>
							<Button variant="secondary" size="sm" disabled>SMS</Button>
							<Button variant="secondary" size="sm" disabled>メール</Button>
							<Button variant="secondary" size="sm" disabled>FPに相談</Button>
						</Stack>
						<p>本人の保険を見直したい/年間69,264円/節約額-530,736円/提案保険料69,264円</p>
					</Container>
					<ListGroup variant="flush" className="border-top-0">
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">電話番号</Col>
								<Col bsPrefix="col-8">00000000000</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">メールアドレス</Col>
								<Col bsPrefix="col-8">0000@.com</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">氏名</Col>
								<Col bsPrefix="col-8">山田太朗</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">カナ氏名</Col>
								<Col bsPrefix="col-8">ヤマダタロウ</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">保険契約者</Col>
								<Col bsPrefix="col-8 fw-bold">本人</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">性別</Col>
								<Col bsPrefix="col-8">男性</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">子供</Col>
								<Col bsPrefix="col-8">0人</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">子供の年齢</Col>
								<Col bsPrefix="col-8">1人目10歳/2人目5歳</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">去年の年収</Col>
								<Col bsPrefix="col-8">500万人</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">月々の保険料</Col>
								<Col bsPrefix="col-8">2万円</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">3大疾病</Col>
								<Col bsPrefix="col-8">経験なし</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">喫煙</Col>
								<Col bsPrefix="col-8">月50,000円（年600,000円）</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">再/精密検査</Col>
								<Col bsPrefix="col-8">なし</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">持ち家の形態</Col>
								<Col bsPrefix="col-8">持ち家</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">配偶者の年収</Col>
								<Col bsPrefix="col-8">500万円</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">保険会社</Col>
								<Col bsPrefix="col-8">アクサ生命</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">加入期間</Col>
								<Col bsPrefix="col-8">60</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">保証内容</Col>
								<Col bsPrefix="col-8">終身保険/収入保障保険/がん保険</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">提供元</Col>
								<Col bsPrefix="col-8">みんかぶ</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">流入元</Col>
								<Col bsPrefix="col-8">min-koukoku1</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item className="pt-4">
							<Row>
								<Col bsPrefix="col-4">提案一つ目</Col>
								<Col bsPrefix="col-8">月2,802円</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">保険会社</Col>
								<Col bsPrefix="col-8">オリックス生命</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">商品名</Col>
								<Col bsPrefix="col-8">終身保険</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col bsPrefix="col-4">保証内容</Col>
								<Col bsPrefix="col-8">終身保険/収入保障保険/がん保険</Col>
							</Row>
						</ListGroup.Item>
					</ListGroup>
				</Accordion.Body>
			</Accordion.Item>
			
		</Accordion>
	)
});