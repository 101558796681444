/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FC, memo } from "react";
import { Card, Button, Stack, Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import { WrapperDefaultLayout } from "../template/WrapperDefaultLayout";
import { SubTitle } from "../atoms/typography/SubTitle";

export const DataImport: FC = memo(() => {
	return (
		<WrapperDefaultLayout>
			<SubTitle>保険データインポート</SubTitle>
			<h3>新規登録</h3>

			<Stack gap={4} className="pt-4 pb-5">
				<div className="d-flex justify-content-between p-0">
					<span>保険マスタ情報CSVダウンロード</span>
					<Button variant="success">ファイルを選択</Button>
				</div>
				<div className="d-flex justify-content-between p-0">
					<span>保険料金情報CSVアップロード</span>
					<Button variant="success">ファイルを選択</Button>
				</div>
				<div className="d-flex justify-content-between p-0">
					<span>診断マッピングCSVアップロード</span>
					<Button variant="success">ファイルを選択</Button>
				</div>
			</Stack>
			<Stack gap={4} className="pb-5">
				<div>
					<div><span>※アップロード可能最大サイズ：10,000KB</span></div>
				</div>
				<div>
					<div className="py-4 px-5" css={css`background:#eaecef;`}>
						<h4 className="text-center pt-2 pb-4">アップロード時の注意事項</h4>
						<p>現保険商材と同一の商品名、登録IDは登録されません。何か注意事項があれば記載してくださいなければこの項目は削除でお願いします。</p>
					</div>
				</div>
				<div className="text-center">
					<Button variant="secondary">この内容で登録する</Button>
				</div>

				<section className="pt-5">
					<Container className="p-0 mb-4" css={css`max-width: 1000px;`}>
						<Row className="align-items-center">
							<Col bsPrefix="col-3">
								<h3>アップロード履歴</h3>
							</Col>
							<Col bsPrefix="col-6">
								<span>履歴は3ヶ月で自動に削除されます</span>
							</Col>
						</Row>
					</Container>

					<Card className="pb-5">
						<Tabs defaultActiveKey="master" className="px-4 pt-2">
							<Tab eventKey="master" title="保険マスタ情報" className="mx-3 mt-4">
								<Container>
									<Row className="my-4">
										<Col bsPrefix="col-3" className="ms-auto text-end">
											<Button variant="success">一括ダウンロード</Button>
										</Col>
									</Row>
									<Row className="mb-3 pb-3 align-items-center border-bottom border-secondary">
										<Col bsPrefix="col-1">
											<input type="checkbox" name="checked" className="form-check-input" />
										</Col>
										<Col bsPrefix="col-2">
											<span>2023.02.01 12:00</span>
										</Col>
										<Col bsPrefix="col-2">
											<span>アカウント名</span>
										</Col>
										<Col bsPrefix="col-2">
											<span>xxxxx.csv</span>
										</Col>
										<Col bsPrefix="col-1">
											<Button variant="primary">DL</Button>
										</Col>
										<Col bsPrefix="col-4">
											<span className="text-danger">エラーの文章が表示されます。</span>
										</Col>
									</Row>

									<Row className="mb-3 pb-3 align-items-center border-bottom border-secondary">
										<Col bsPrefix="col-1">
											<input type="checkbox" name="checked" className="form-check-input" />
										</Col>
										<Col bsPrefix="col-2">
											<span>2023.12.01 12:00</span>
										</Col>
										<Col bsPrefix="col-2">
											<span>アカウント名</span>
										</Col>
										<Col bsPrefix="col-2">
											<span>xxxxx.csv</span>
										</Col>
										<Col bsPrefix="col-1">
											<Button variant="primary">DL</Button>
										</Col>
										<Col bsPrefix="col-4">
											<span className="text-danger">エラーの文章が表示されます。</span>
										</Col>
									</Row>
								</Container>
							</Tab>

							<Tab eventKey="insurance" title="保険料金情報CSVアップロード" className="mx-3 mt-4">
								<Container>
									<Row className="my-4">
										<Col bsPrefix="col-3" className="ms-auto text-end">
											<Button variant="success">一括ダウンロード</Button>
										</Col>
									</Row>
									<Row className="mb-3 pb-3 align-items-center border-bottom border-secondary">
										<Col bsPrefix="col-1">
											<input type="checkbox" name="checked" className="form-check-input" />
										</Col>
										<Col bsPrefix="col-2">
											<span>2023.12.01 12:00</span>
										</Col>
										<Col bsPrefix="col-2">
											<span>アカウント名</span>
										</Col>
										<Col bsPrefix="col-2">
											<span>xxxxx.csv</span>
										</Col>
										<Col bsPrefix="col-1">
											<Button variant="primary">DL</Button>
										</Col>
										<Col bsPrefix="col-4">
											<span className="text-danger">エラーの文章が表示されます。</span>
										</Col>
									</Row>

									<Row className="mb-3 pb-3 align-items-center border-bottom border-secondary">
										<Col bsPrefix="col-1">
											<input type="checkbox" name="checked" className="form-check-input" />
										</Col>
										<Col bsPrefix="col-2">
											<span>2023.02.01 12:00</span>
										</Col>
										<Col bsPrefix="col-2">
											<span>アカウント名</span>
										</Col>
										<Col bsPrefix="col-2">
											<span>xxxxx.csv</span>
										</Col>
										<Col bsPrefix="col-1">
											<Button variant="primary">DL</Button>
										</Col>
										<Col bsPrefix="col-4">
											<span className="text-danger">エラーの文章が表示されます。</span>
										</Col>
									</Row>
								</Container>
							</Tab>
							<Tab eventKey="maping" title="診断マッピングCSVアップロード" className="mx-3 mt-4">
								<Container>
									<Row className="my-4">
										<Col bsPrefix="col-3" className="ms-auto text-end">
											<Button variant="success">一括ダウンロード</Button>
										</Col>
									</Row>
									<Row className="mb-3 pb-3 align-items-center border-bottom border-secondary">
										<Col bsPrefix="col-1">
											<input type="checkbox" name="checked" className="form-check-input" />
										</Col>
										<Col bsPrefix="col-2">
											<span>2123.02.11 12:00</span>
										</Col>
										<Col bsPrefix="col-2">
											<span>アカウント名</span>
										</Col>
										<Col bsPrefix="col-2">
											<span>xxxxx.csv</span>
										</Col>
										<Col bsPrefix="col-1">
											<Button variant="primary">DL</Button>
										</Col>
										<Col bsPrefix="col-4">
											<span className="text-danger">エラーの文章が表示されます。</span>
										</Col>
									</Row>

									<Row className="mb-3 pb-3 align-items-center border-bottom border-secondary">
										<Col bsPrefix="col-1">
											<input type="checkbox" name="checked" className="form-check-input" />
										</Col>
										<Col bsPrefix="col-2">
											<span>2023.02.11 12:00</span>
										</Col>
										<Col bsPrefix="col-2">
											<span>アカウント名</span>
										</Col>
										<Col bsPrefix="col-2">
											<span>xxxxx.csv</span>
										</Col>
										<Col bsPrefix="col-1">
											<Button variant="primary">DL</Button>
										</Col>
										<Col bsPrefix="col-4">
											<span className="text-danger">エラーの文章が表示されます。</span>
										</Col>
									</Row>
								</Container>
							</Tab>

						</Tabs>
					</Card>
				</section>
			</Stack>
		</WrapperDefaultLayout>
	);
});