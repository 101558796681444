/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FC, memo, ReactNode } from "react";
import { Header } from "../atoms/Header";

type Props = {
    children: ReactNode;
}

export const WideFullPageLayout: FC<Props> = memo((props) => {
    const { children } = props;
    return (
        <>        
            <Header />
            <div css={wrapperWide}>
                { children }
            </div>
        </>
    );
}) ;

const wrapperWide = css`
    width: 96%;
    max-width: 2000px;
    margin: 0 auto 40px;
    height: calc(100vh - 200px - 1.5rem);;
    overflow: auto hidden;
    section {
        overflow-y: auto;
        flex-shrink: 0;
    }
`