/**  @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { Container, Row, Col, Form, Button, Stack } from "react-bootstrap";
import { css } from "@emotion/react";

export const UserDataForm: FC = memo(() => {
	return (
		<section>
			<Form>
				<Container className="mb-4">
					<p css={css`font-size:24px;font-weight:bold;`}>お客様情報</p>
					<Row className="mb-4">
						<Col sm={6} className="mb-2">
							<Form.Group>
								<Form.Label>電話番号</Form.Label>
								<Form.Control type="tel" placeholder="0312345678（ハイフンなし）" />
							</Form.Group>
						</Col>
						<Col sm={6} className="mb-2">
							<Form.Group>
								<Form.Label>メールアドレス</Form.Label>
								<Form.Control type="email" placeholder="example@email.com" />
							</Form.Group>
						</Col>
						<Col sm={6} className="mb-2">
							<Form.Group>
								<Form.Label>氏名</Form.Label>
								<Form.Control type="text" placeholder="山田　太郎" />
							</Form.Group>
						</Col>
						<Col sm={6} className="mb-2">
							<Form.Group>
								<Form.Label>カナ氏名</Form.Label>
								<Form.Control type="text" placeholder="ヤマダ　タロウ" />
							</Form.Group>
						</Col>
					</Row>
				</Container>
				<Container className="mb-4">
					<p css={css`font-size:24px;font-weight:bold;`}>基本情報</p>
					<Stack gap={4}>
						<Row>
							<Col>
								<span>保険加入者</span>
								<div>
									<Form.Check
										inline
										label='本人'
										name='group1'
										type='radio'
										id="me"
									/>
									<Form.Check
										inline
										label='夫'
										name='group1'
										type='radio'
										id="husband"
									/>
									<Form.Check
										inline
										label='妻'
										name='group1'
										type='radio'
										id="wife"
									/>
									<Form.Check
										inline
										label='その他'
										name='group1'
										type='radio'
										id="other"
									/>
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<span>性別</span>
								<div>
									<Form.Check
										inline
										label='男性'
										name='group2'
										type='radio'
										id="M"
									/>
									<Form.Check
										inline
										label='女性'
										name='group2'
										type='radio'
										id="F"
									/>
								</div>
							</Col>
						</Row>
						<Row>
							<Col md={4} lg={3}>
								<span>保険加入者の年齢<span css={formRequired}>（必須）</span></span>
								<Form.Group css={formUnit()} data-unit="歳">
									<Form.Control type="text" placeholder="年齢" />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col>
								<span>配偶者<span css={formRequired}>（必須）</span></span>
								<div>
									<Form.Check
										inline
										label='あり'
										name='radio-spouse'
										type='radio'
										id="radio-spouse-true"
									/>
									<Form.Check
										inline
										label='なし'
										name='radio-spouse'
										type='radio'
										id="radio-spouse-false"
									/>
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<span>扶養する子供の数<span css={formRequired}>（必須）</span></span>
								<div>
									<Form.Check
										inline
										label='いない'
										name='radio-spouse-children'
										type='radio'
										id="radio-spouse-children-0"
									/>
									<Form.Check
										inline
										label='1人'
										name='radio-spouse-children'
										type='radio'
										id="radio-spouse-children-1"
									/>
									<Form.Check
										inline
										label='2人'
										name='radio-spouse-children'
										type='radio'
										id="radio-spouse-children-2"
									/>
									<Form.Check
										inline
										label='3人'
										name='radio-spouse-children'
										type='radio'
										id="radio-spouse-children-3"
									/>
									<Form.Check
										inline
										label='4人以上'
										name='radio-spouse-children'
										type='radio'
										id="radio-spouse-children-4"
									/>
								</div>
							</Col>
						</Row>
						<Row>
							<span>子供の年齢</span>
							<Col md={2} className="me-0 mb-4 me-md-4 mb-me-0">
								<Form.Group css={formUnit()} data-unit="歳">
									<Form.Control type="text" placeholder="1人目" />
								</Form.Group>
							</Col>
							<Col md={2} className="me-0 mb-4 me-md-4 mb-me-0">
								<Form.Group css={formUnit()} data-unit="歳">
									<Form.Control type="text" placeholder="2人目" />
								</Form.Group>
							</Col>
							<Col md={2} className="me-0 mb-4 me-md-4 mb-me-0">
								<Form.Group css={formUnit()} data-unit="歳">
									<Form.Control type="text" placeholder="3人目" />
								</Form.Group>
							</Col>
							<Col md={2} className="me-0 mb-4 me-md-4 mb-me-0">
								<Form.Group css={formUnit()} data-unit="歳">
									<Form.Control type="text" placeholder="4人目" />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<span>去年の年収<span css={formRequired}>（必須）</span></span>
							<Col md={2}>
								<Form.Group css={formUnit(-2.5)} data-unit="万円">
									<Form.Control type="text" placeholder="およそ" />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<span>月々の支出は<span css={formRequired}>（必須）</span></span>
							<Col md={2}>
								<Form.Group css={formUnit(-2.5)} data-unit="万円">
									<Form.Control type="text" placeholder="およそ" />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<span>現在の月々の保険料は<span css={formRequired}>（必須）</span></span>
							<Col md={2}>
								<Form.Group css={formUnit(-2.5)} data-unit="万円">
									<Form.Control type="text" placeholder="およそ" />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<span>3大疾病の経験<span css={formRequired}>（必須）</span></span>
							<Col>
								<div>
									<Form.Check
										inline
										label='あり'
										name='radio-disease'
										type='radio'
										id="radio-disease-true"
									/>
									<Form.Check
										inline
										label='なし'
										name='radio-disease'
										type='radio'
										id="radio-disease-false"
									/>
								</div>
							</Col>
						</Row>
						<Row>
							<span>1年以内の喫煙</span>
							<Col>
								<div>
									<Form.Check
										inline
										label='あり'
										name='radio-smoking'
										type='radio'
										id="radio-smoking-true"
									/>
									<Form.Check
										inline
										label='なし'
										name='radio-smoking'
										type='radio'
										id="radio-smoking-false"
									/>
									<Form.Check
										inline
										label='不明'
										name='radio-smoking'
										type='radio'
										id="radio-smoking-none"
									/>
								</div>
							</Col>
						</Row>
						<Row>
							<span>1年以内の再検査・精密検査</span>
							<Col>
								<div>
									<Form.Check
										inline
										label='あり'
										name='radio-again'
										type='radio'
										id="radio-again-true"
									/>
									<Form.Check
										inline
										label='なし'
										name='radio-again'
										type='radio'
										id="radio-again-false"
									/>
								</div>
							</Col>
						</Row>
						<Row>
							<span>住まいの形態</span>
							<Col>
								<div>
									<Form.Check
										inline
										label='持ち家'
										name='radio-house'
										type='radio'
										id="radio-house-owned"
									/>
									<Form.Check
										inline
										label='貸家'
										name='radio-house'
										type='radio'
										id="radio-house-rental"
									/>
								</div>
							</Col>
						</Row>
						<Row>
							<span>月々の家賃<span css={formRequired}>（必須）</span></span>
							<Col md={2}>
								<Form.Group css={formUnit(-2.5)} data-unit="万円">
									<Form.Control type="text" placeholder="およそ" />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<span>配偶者の年収</span>
							<Col md={2}>
								<Form.Group css={formUnit(-2.5)} data-unit="万円">
									<Form.Control type="text" placeholder="およそ" />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<span>現在加入している保険会社</span>
							<Col md={5}>
								<Form.Select aria-label="insurance">
									<option value="アクサ生命">アクサ生命</option>
									<option value="アクサ生命">アクサ生命</option>
									<option value="アクサ生命">アクサ生命</option>
									<option value="アクサ生命">アクサ生命</option>
									<option value="アクサ生命">アクサ生命</option>
									<option value="アクサ生命">アクサ生命</option>
								</Form.Select>
							</Col>
						</Row>
						<Row>
							<span>加入期間</span>
							<Col md={2}>
								<Form.Group css={formUnit(-2.5)} data-unit="ヶ月">
									<Form.Control type="text" placeholder="およそ" />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<span>保障内容は</span>
							<Col>
								<div>
									<Form.Check
										inline
										label='死亡定期保険'
										name='checkbox-0'
										type='checkbox'
										id="死亡定期保険"
									/>
									<Form.Check
										inline
										label='終身保険'
										name='checkbox-0'
										type='checkbox'
										id="終身保険"
									/>
									<Form.Check
										inline
										label='収入保障保険'
										name='checkbox-0'
										type='checkbox'
										id="収入保障保険"
									/>
									<Form.Check
										inline
										label='医療保障'
										name='checkbox-0'
										type='checkbox'
										id="医療保障"
									/>
									<Form.Check
										inline
										label='がん保障'
										name='checkbox-0'
										type='checkbox'
										id="がん保障"
									/>
									<div css={css`display:inline-flex;`}>
										<Form.Check
											inline
											label='その他'
											name='checkbox-0'
											type='checkbox'
											id="その他"
										/>
										<Form.Group data-unit="">
											<Form.Control type="text" placeholder="" />
										</Form.Group>
									</div>
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<Stack direction="horizontal" gap={3}>
									<Button variant="primary">登録</Button>
									<Button variant="secondary">キャンセル</Button>
								</Stack>
							</Col>
						</Row>
					</Stack>
				</Container>
			</Form>
		</section>
	);
});
const formUnit = (space: number = -1.5) => css`
	position: relative;
	&:after{
		content: attr(data-unit);
		position: absolute;
		top: 50%;
		right: ${`${space}em`};
		transform: translateY(-50%);
	}
`
const formRequired = css`
	color: #f00;
`