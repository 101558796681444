/** @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css } from "@emotion/react";

type Props = {
    children: string;
}

export const SubTitle: FC<Props> = memo((props) => { 
    const { children } = props;
    return <h2 className="fw-bold text-primary py-4 mb-0" css={subTitle}>{children}</h2>;
});

const subTitle = css`
    font-size: min(1.25rem + 1.5vw,24px);
`