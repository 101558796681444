/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FC, memo } from "react";
import { Button, Stack, Container, Row, Col, Form, InputGroup } from "react-bootstrap";

export const AccountPopup: FC = memo(() => {
	return (
		<div css={popupWrapper} className="accountpopup px-5 py-4">
			<Stack gap={3}>
				<span className="fs-4">アカウント編集</span>
				<span className="fs-4">No.xxxx</span>
				<Form className="mt-3">
					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm="2">氏名</Form.Label>
						<Col sm="10">
							<Form.Control type="text" placeholder="山田 太郎" />
						</Col>
					</Form.Group>
					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm="2">gmail</Form.Label>
						<Col sm="10">
							<Form.Control type="email" placeholder="xxx@gmail.com" />
						</Col>
					</Form.Group>


					<div className="text-light bg-primary text-center w-100 mt-4 py-2">項目</div>
					<Container className="bg-white">

						<Row className="py-2 d-flex justify-content-center align-items-center border-bottom border-light">
							<Form.Label column bsPrefix="col-10">新規カード作成（発行）</Form.Label>
							<Col>
								<Form.Check />
							</Col>
						</Row>

						<Row className="py-2 d-flex justify-content-center align-items-center border-bottom border-light">
							<Form.Label column bsPrefix="col-10">カード削除</Form.Label>
							<Col>
								<Form.Check />
							</Col>
						</Row>
						<Row className="py-2 d-flex justify-content-center align-items-center border-bottom border-light">
							<Form.Label column bsPrefix="col-10">カード編集</Form.Label>
							<Col>
								<Form.Check />
							</Col>
						</Row>
						<Row className="py-2 d-flex justify-content-center align-items-center border-bottom border-light">
							<Form.Label column bsPrefix="col-10">ユーザー情報インポート</Form.Label>
							<Col>
								<Form.Check />
							</Col>
						</Row>
						<Row className="py-2 d-flex justify-content-center align-items-center border-bottom border-light">
							<Form.Label column bsPrefix="col-10">ユーザー情報エクスポート</Form.Label>
							<Col>
								<Form.Check />
							</Col>
						</Row>
						<Row className="py-2 d-flex justify-content-center align-items-center border-bottom border-light">
							<Form.Label column bsPrefix="col-10">カードインポート</Form.Label>
							<Col>
								<Form.Check />
							</Col>
						</Row>
						<Row className="py-2 d-flex justify-content-center align-items-center border-bottom border-light">
							<Form.Label column bsPrefix="col-10">カードエクスポート</Form.Label>
							<Col>
								<Form.Check />
							</Col>
						</Row>
						<Row className="py-2 d-flex justify-content-center align-items-center border-bottom border-light">
							<Form.Label column bsPrefix="col-10">アカウント編集・権限付与</Form.Label>
							<Col>
								<Form.Check />
							</Col>
						</Row>
						<Row className="py-2 d-flex justify-content-center align-items-center border-bottom border-light">
							<Form.Label column bsPrefix="col-10">権限グループ作成・編集・削除</Form.Label>
							<Col>
								<Form.Check />
							</Col>
						</Row>
						<Row className="py-2 d-flex justify-content-center align-items-center border-bottom border-light">
							<Form.Label column bsPrefix="col-10">商品情報編集</Form.Label>
							<Col>
								<Form.Check />
							</Col>
						</Row>
						<Row className="py-2 d-flex justify-content-center align-items-center border-bottom border-light">
							<Form.Label column bsPrefix="col-10">パンフレット（PDF）インポート・削除</Form.Label>
							<Col>
								<Form.Check />
							</Col>
						</Row>
						<Row className="py-2 d-flex justify-content-center align-items-center border-bottom border-light">
							<Form.Label column bsPrefix="col-10">提携先ごとの権限付与</Form.Label>
							<Col>
								<Form.Check />
							</Col>
						</Row>
					</Container>
						<div className="my-4 d-flex justify-content-center gap-4">
							<Button variant="danger">一括チェック</Button>
							<Button variant="primary">保存する</Button>
						</div>
				</Form>
			</Stack>
		</div>
	);
});

const popupWrapper = css`
max-width: 500px;
width: 100%;
max-height: 640px;
height: 100%;
overflow-y: scroll;
background: #eaecef;
`