/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FC, memo } from "react";
import { Button, Stack, Container, Row, Col, Form } from "react-bootstrap";

export const ManagementListPopup: FC = memo(() => {
	return (
		<div css={popupWrapper} className="accountpopup px-5 py-4">
			<Stack gap={3}>
				<span className="fs-4">管理区分編集</span>
				<span className="fs-4">No.xxxx</span>
				<Form>
					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm="3">会社名</Form.Label>
						<Col sm="9">
							<Form.Control type="text" placeholder="" />
						</Col>
					</Form.Group>
					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm="3">SMTP</Form.Label>
						<Col sm="9">
							<Form.Control type="text" placeholder="" />
						</Col>
					</Form.Group>
					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm="3">ロゴ</Form.Label>
						<Col sm="5">
							<Button variant="outline-primary">アップロード</Button>
						</Col>
					</Form.Group>
					<Form.Group as={Row} className="mb-3">
						<Form.Label column sm="12">サブドメイン</Form.Label>
						<Col sm="12">
							<Form.Control type="text" placeholder="" />
						</Col>
					</Form.Group>


					<Container className="mt-5">
						<Row className="d-flex align-items-center mb-3">
							<Col>メインカラー</Col>
							<Col className="d-flex align-items-center gap-4">
								<Form.Control
									type="color"
									id="mainColorInput"
									defaultValue="#0d6efd"
									title="Choose your color"
									css={css`
									padding: 0%;
									border: none;
									border-radius: 0;
									width: 40px;
									height: 40px;
									background-color: transparent;
								`}
								/>
								<Form.Label htmlFor="mainColorInput">
									<span className="btn btn-outline-primary">色選択</span>
								</Form.Label>
							</Col>
						</Row>

						<Row className="d-flex align-items-center mb-3">
							<Col>サブカラー</Col>
							<Col className="d-flex align-items-center gap-4">
								<Form.Control
									type="color"
									id="subColorInput"
									defaultValue="#E03C4E"
									title="Choose your color"
									css={css`
									padding: 0%;
									border: none;
									border-radius: 0;
									width: 40px;
									height: 40px;
									background-color: transparent;
								`}
								/>
								<Form.Label htmlFor="subColorInput">
									<span className="btn btn-outline-primary">色選択</span>
								</Form.Label>
							</Col>
						</Row>
						<Row className="d-flex align-items-center mb-3">
							<Col>サブカラー2</Col>
							<Col className="d-flex align-items-center gap-4">
								<Form.Control
									type="color"
									id="subColor2Input"
									defaultValue="#2DB04E"
									title="Choose your color"
									css={css`
									padding: 0%;
									border: none;
									border-radius: 0;
									width: 40px;
									height: 40px;
									background-color: transparent;
								`}
								/>
								<Form.Label htmlFor="subColor2Input">
									<span className="btn btn-outline-primary">色選択</span>
								</Form.Label>
							</Col>
						</Row>

						<Row className="d-flex align-items-center mb-3">
							<Col>サブカラー3</Col>
							<Col className="d-flex align-items-center gap-4">
								<Form.Control
									type="color"
									id="subColor3Input"
									defaultValue="#778088"
									title="Choose your color"
									css={css`
									padding: 0%;
									border: none;
									border-radius: 0;
									width: 40px;
									height: 40px;
									background-color: transparent;
								`}
								/>
								<Form.Label htmlFor="subColor3Input">
									<span className="btn btn-outline-primary">色選択</span>
								</Form.Label>
							</Col>
						</Row>

						<Row className="d-flex align-items-center mb-3">
							<Col>背景カラー</Col>
							<Col className="d-flex align-items-center gap-4">
								<Form.Control
									type="color"
									id="backgroundInput"
									defaultValue="#EEF5F5"
									title="Choose your color"
									css={css`
									padding: 0%;
									border: none;
									border-radius: 0;
									width: 40px;
									height: 40px;
									background-color: transparent;
								`}
								/>
								<Form.Label htmlFor="backgroundInput">
									<span className="btn btn-outline-primary">色選択</span>
								</Form.Label>
							</Col>
						</Row>

						<Row className="d-flex align-items-center mb-3">
							<Col>比較メイン</Col>
							<Col className="d-flex align-items-center gap-4">
								<Form.Control
									type="color"
									id="comparisonMainColorInput"
									defaultValue="#002A83"
									title="Choose your color"
									css={css`
									padding: 0%;
									border: none;
									border-radius: 0;
									width: 40px;
									height: 40px;
									background-color: transparent;
								`}
								/>
								<Form.Label htmlFor="comparisonMainColorInput">
									<span className="btn btn-outline-primary">色選択</span>
								</Form.Label>
							</Col>
						</Row>

						<Row className="d-flex align-items-center mb-3">
							<Col>比較アクセント</Col>
							<Col className="d-flex align-items-center gap-4">
								<Form.Control
									type="color"
									id="comparisonAccentColorInput"
									defaultValue="#EF7B33"
									title="Choose your color"
									css={css`
									padding: 0%;
									border: none;
									border-radius: 0;
									width: 40px;
									height: 40px;
									background-color: transparent;
								`}
								/>
								<Form.Label htmlFor="comparisonAccentColorInput">
									<span className="btn btn-outline-primary">色選択</span>
								</Form.Label>
							</Col>
						</Row>

					</Container>
				</Form>
			</Stack>
			<div className="my-4 d-flex justify-content-center gap-4">
				<Button variant="primary">保存する</Button>
			</div>
		</div>
	);
});

const popupWrapper = css`
max-width: 500px;
width: 100%;
max-height: 640px;
height: 100%;
overflow-y: scroll;
background: #eaecef;
`