import { FC, memo, useCallback, useMemo } from "react";
import { FileWithPath, useDropzone } from "react-dropzone";
import { SubTitle } from "../atoms/typography/SubTitle";
import { WrapperDefaultLayout } from "../template/WrapperDefaultLayout";
import { ReactComponent as ImageIcon } from "../../assets/images/image.svg";
import { Button, Col, Form, Row, Stack } from "react-bootstrap";


export const ProductInfoEdit: FC = memo(() => { 
    const onDrop = useCallback((files: File[]) => {
        // Do something with the files
        console.log('files:', files);
      }, []);
    const { getRootProps, getInputProps, isDragActive, open, acceptedFiles } = useDropzone({ onDrop, noClick: true });
    const filesUpdated: FileWithPath[] = acceptedFiles;

    const files = useMemo(() =>
        filesUpdated.map(file => (
        <li className="py-2" key={file.path}>
            {file.path} - {file.size} bytes
        </li>
        )
    ), [filesUpdated]);
    return (
        <WrapperDefaultLayout>
            <SubTitle>保険商品情報編集</SubTitle>
            <Form>
                <Form.Group className="mb-4" controlId="formDndFile">
                    <Form.Label>イメージ画像登録</Form.Label>
                    <div className="p-4 text-white bg-secondary text-center">
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <ImageIcon className="mb-2"/>
                            {
                            isDragActive ?
                                <p>ここにファイルをドロップしてください<br />または</p> :
                                <p>ファイルをドラッグ＆ドロップするかクリップボードから画像を貼り付けしてください<br />または</p>
                            }
                            <Button variant="light" onClick={open}>ファイルを選択</Button>
                        </div>
                        <aside>
                            <ul className="list-unstyled mb-0">{files}</ul>
                        </aside>
                    </div>
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Label>保険の有効期限</Form.Label>
                    <Row>
                        <Col md={4}>
                            <Form.Control />
                        </Col>
                        <Col md="auto" className="align-self-center">〜</Col>
                        <Col md={4}>
                            <Form.Control />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Label>PDFアップロード（複数の場合はzipでアップロードしてください）</Form.Label>
                    <div className="p-4 text-white bg-secondary text-center">
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <ImageIcon className="mb-2"/>
                            {
                            isDragActive ?
                                <p>ここにファイルをドロップしてください<br />または</p> :
                                <p>ファイルをドラッグ＆ドロップするかクリップボードから画像を貼り付けしてください<br />または</p>
                            }
                            <Button variant="light" onClick={open}>ファイルを選択</Button>
                        </div>
                        <aside>
                            <ul className="list-unstyled mb-0">{files}</ul>
                        </aside>
                    </div>
                </Form.Group>
                <Stack direction="horizontal" gap={3} className="mt-5 justify-content-center">
                    <Button variant="secondary">キャンセル</Button>
                    <Button variant="primary">登録</Button>
                </Stack>
            </Form>

        </WrapperDefaultLayout>
    );
});