/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FC, memo } from "react";
import { Container, Nav, Navbar, Stack } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/images/FinancialAgencyLogo.svg";

export const Header: FC = memo(() => {
    return (
        <header css={header}>
            <Stack direction="horizontal" gap={3}>
                <h1 css={title}>保デジ管理</h1>
                <Logo />
            </Stack>
            <Navbar bg="light" variant="light" className="my-4 rounded-2">
                <Container>
                    <Nav className="me-auto">
                        <Nav.Link as={NavLink} to="/home">ホーム</Nav.Link>
                        <Nav.Link as={NavLink} to="/search">保険一覧検索</Nav.Link>
                        <Nav.Link as={NavLink} to="/account">アカウント管理</Nav.Link>
                        <Nav.Link as={NavLink} to="/partner">提携先設定</Nav.Link>
                        <Nav.Link as={NavLink} to="/import">保険登録</Nav.Link>
                        <Nav.Link as={NavLink} to="/management">管理区分一覧</Nav.Link>
                        <Nav.Link as={NavLink} to="/email">メールテンプレート編集</Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
        </header>
    );
});

const header = css`
    width: 96%;
    max-width:1000px;
    margin:0 auto;
`
const title = css`
    font-size: min(1.375rem + 1.5vw,32px);
    font-weight: bold;
    margin: 0;
`