/**  @jsxImportSource @emotion/react */
import { FC, memo } from "react";
import { css, Global } from '@emotion/react';
import { Button, ButtonGroup, Dropdown, Form } from "react-bootstrap";
import { Link } from "react-router-dom";

export const ComparisonList: FC = memo(() => {
	return (
		<section>
				<Global styles={
					css`
					.btn-naivyButtonGroup{
						--bs-btn-color: #000;
						--bs-btn-bg: #fff;
						--bs-btn-border-color:#ced4dA;
						--bs-btn-hover-color: #fff;
						--bs-btn-hover-bg: #00153e;
						--bs-btn-hover-border-color:#00153e;
						--bs-btn-active-color: #fff;
						--bs-btn-active-bg: #00153e;
						--bs-btn-active-border-color: #00153e;
					}
					.btn-dropdown{
						--bs-btn-color: #000;
						--bs-btn-bg: #fff;
						--bs-btn-border-color:#ced4dA;
						--bs-btn-hover-color: #000;
						--bs-btn-hover-bg: #fff;
						--bs-btn-hover-border-color:#ced4dA;
						--bs-btn-active-color: #000;
						--bs-btn-active-bg: #fff;
						--bs-btn-active-border-color: #ced4dA;
						&:after{
							position: absolute;
							transform: translateY(-50%);
							top: 50%;
							right: 0.5em;
							border-top: 0.6em solid;
							border-top-color: #f60;
						}
					}
					.btn-orange{
						--bs-btn-bg: #f60;
						--bs-btn-color: #fff;
						--bs-btn-hover-bg: #f80;
						--bs-btn-hover-border-color:#f60;
						--bs-btn-hover-color: #fff;
					}
				`} />
				<section>
					<div>
						<h2 css={[typographyComparisonCategoryTitle, CategoryTitleMargin]}>保険の種類</h2>
						<ButtonGroup css={[buttongroupWrap, buttongroupWrapMargin, buttongroupWrapColumns(6, 2, 2)]}>
							<Button variant='naivyButtonGroup'>医療保険</Button>
							<Button variant='naivyButtonGroup'>医療保険（緩和型）</Button>
							<Button variant='naivyButtonGroup'>がん保険</Button>
							<Button variant='naivyButtonGroup'>死亡保険（終身）</Button>
							<Button variant='naivyButtonGroup'>死亡保険（定期）</Button>
							<Button variant='naivyButtonGroup'>収入保障保険</Button>
						</ButtonGroup>
					</div>
					<div>
						<h2 css={[typographyComparisonCategoryTitle, CategoryTitleMargin]}>保険料の予算（月払い）</h2>
						<ButtonGroup css={[buttongroupWrap, buttongroupWrapMargin, buttongroupWrapColumns(8, 2, 2)]}>
							<Button variant='naivyButtonGroup'>指定しない</Button>
							<Button variant='naivyButtonGroup'>〜3,000円</Button>
							<Button variant='naivyButtonGroup'>〜5,000円</Button>
							<Button variant='naivyButtonGroup'>〜8,000円</Button>
							<Button variant='naivyButtonGroup'>〜10,000円</Button>
							<Button variant='naivyButtonGroup'>〜15,000円</Button>
							<Button variant='naivyButtonGroup'>〜20,000円</Button>
							<Button variant='naivyButtonGroup'>〜30,000円</Button>
						</ButtonGroup>
					</div>
					<div>
						<h2 css={[typographyComparisonCategoryTitle, CategoryTitleMargin]}>並び替え</h2>
						<ButtonGroup css={[buttongroupWrap, buttongroupWrapMargin, buttongroupWrapColumns(2, 2, 2), css`
						width:50%;
						@media (max-width: 576px){
							width: 100%
						}
						`]}>
							<Button variant='naivyButtonGroup'>保険料が安い順</Button>
							<Button variant='naivyButtonGroup'>保険料が高い順</Button>
						</ButtonGroup>
					</div>
					<div css={css`
						display:flex;
						gap:40px;
						flex-wrap: wrap;
						@media(max-width: 992px){
							width: calc(50% + 15px);
							gap: 15px;
						}
						@media(max-width: 576px){
							width: 100%;
							gap:40px;
							flex-flow: column;
						}
						`}>
						<div>
							<h3 css={[typographyComparisonCategoryTitle, dropDownTitle]}>年齢</h3>
							<Dropdown css={css`display:inline;`}>
								<Dropdown.Toggle css={css`
									width:175px;
									text-align:left;
									@media (max-width: 576px){
										width: 100%
									}
									`} variant="dropdown">30歳</Dropdown.Toggle>
								<Dropdown.Menu css={css`
									width:175px;
									@media (max-width: 576px){
										width: 100%
									}
								`}>
									<Dropdown.Item>30歳</Dropdown.Item>
									<Dropdown.Item>30歳</Dropdown.Item>
									<Dropdown.Item>30歳</Dropdown.Item>
									<Dropdown.Item>30歳</Dropdown.Item>
									<Dropdown.Item>30歳</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</div>
						<div>
							<h3 css={[typographyComparisonCategoryTitle, dropDownTitle]}>性別</h3>
							<Dropdown css={css`display:inline;`}>
								<Dropdown.Toggle css={css`
									width:175px;
									text-align:left;
									@media (max-width: 576px){
										width: 100%
									}
									`} variant="dropdown">男性</Dropdown.Toggle>
								<Dropdown.Menu css={css`
									width:175px;
									@media (max-width: 576px){
										width: 100%
									}
									`}>
									<Dropdown.Item>男性</Dropdown.Item>
									<Dropdown.Item>女性</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</div>
						<div>
							<h3 css={[typographyComparisonCategoryTitle, dropDownTitle]}>保障額</h3>
							<Dropdown css={css`display:inline;`}>
								<Dropdown.Toggle css={css`
									width:320px;
									text-align:left;
									@media (max-width: 576px){
										width: 100%
									}
									`} variant="dropdown">入院日額3,000円</Dropdown.Toggle>
								<Dropdown.Menu css={css`width:175px;`}>
									<Dropdown.Item>入院日額3,000円</Dropdown.Item>
									<Dropdown.Item>入院日額3,000円</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</div>
						<div>
							<h3 css={[typographyComparisonCategoryTitle, dropDownTitle]}>選べる</h3>
							<Button variant='orange' css={addIcon}>くわしく設定</Button>
						</div>
					</div>
				</section>
				{/* 商品表 PC ここから*/}
				<section css={css`margin-top: 47px;`}>
					<div css={css`padding: 15px 0;border: 1px solid #000;background: #fff;text-align:center;margin-left:200px;`}>
						比較表【死亡保険（定期）】死亡保険金1,000万円
					</div>
					<div css={css`display:flex;column-gap:22px;`}>
						<div css={css`width: 180px;flex-shrink:0;`}>
							{/* 見出し */}
							<div css={[radioBtn, css`
								height: 55px;
								display: flex;
								justify-content: center;
								align-items: center;
								`]}>
								<button css={css`
								border:1px solid #000;
								border-radius:20px;
								display:block;
								line-height:1;
								padding: 8px 11px;
								background: #fff;
								`}>チェックした<br />項目で比較</button>
							</div>
							<div css={[typographyAlignCenter, listHead, css`height:50px;`]}>
								<span css={typographyBlock}>
									保険料ランキング
								</span>
							</div>
							<div css={[typographyAlignCenter, listHead, css`height:102px;`]}>
								<span css={typographyBlock}>
									保険会社・商品<br />（五十音順）
								</span>
							</div>
							<div css={[typographyAlignCenter, listHead, css`height:50px;`]}>
								<span css={typographyBlock}>
									月払い保険料
								</span>
							</div>
							<div css={[typographyAlignCenter, listHead, css`height:75px;`]}>
								<span css={typographyBlock}>
									お申し込み
								</span>
							</div>
							<div css={[typographyAlignCenter, listHead, css`height:60px;`]}>
								<span css={typographyBlock}>
									見積もり結果<br />に保存
								</span>
							</div>
							{/* 2カラム */}
							<div css={css`display:grid;`}>
								<div css={[css`grid-row:1/4;grid-column:1/2;writing-mode: vertical-rl;display:flex;align-items:center;justify-content:center;`, listHead]}>
									<span css={css`letter-spacing:1em;`}>入院保障</span>
								</div>
								<div css={css`grid-row:1/4;grid-column:2/4;`}>
									<div css={[typographyAlignCenter, listHead, css`height:162px;`]}>
										<span css={typographyBlock}>
											入院日額
										</span>
										<Dropdown>
											<Dropdown.Toggle variant="dropdown" css={listHeadDropdown}>指定なし</Dropdown.Toggle>
											<Dropdown.Menu>
												<Dropdown.Item>指定なし</Dropdown.Item>
												<Dropdown.Item>指定なし</Dropdown.Item>
												<Dropdown.Item>指定なし</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									</div>
									<div css={[typographyAlignCenter, listHead, css`height:100px;`]}>
										<span css={typographyBlock}>
											入院日額
										</span>
										<Dropdown>
											<Dropdown.Toggle variant="dropdown" css={listHeadDropdown}>指定なし</Dropdown.Toggle>
											<Dropdown.Menu>
												<Dropdown.Item>指定なし</Dropdown.Item>
												<Dropdown.Item>指定なし</Dropdown.Item>
												<Dropdown.Item>指定なし</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									</div>
									<div css={[typographyAlignCenter, listHead, css`height:100px;`]}>
										<span css={typographyBlock}>
											入院一時金
										</span>
										<Dropdown>
											<Dropdown.Toggle variant="dropdown" css={listHeadDropdown}>指定なし</Dropdown.Toggle>
											<Dropdown.Menu>
												<Dropdown.Item>指定なし</Dropdown.Item>
												<Dropdown.Item>指定なし</Dropdown.Item>
												<Dropdown.Item>指定なし</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									</div>
								</div>
							</div>
							<div css={[typographyAlignCenter, listHead, css`height:100px;`]}>
								<span css={typographyBlock}>
									手術保障
								</span>
								<Dropdown>
									<Dropdown.Toggle variant="dropdown" css={listHeadDropdown}>指定なし</Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item>指定なし</Dropdown.Item>
										<Dropdown.Item>指定なし</Dropdown.Item>
										<Dropdown.Item>指定なし</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							{/* 2カラム */}
							<div css={css`display:grid;`}>
								<div css={[css`grid-row:1/4;grid-column:1/2;writing-mode: vertical-rl;display:flex;align-items:center;justify-content:center;`, listHead]}>
									<span css={css`letter-spacing:1em;`}>通院保障</span>
								</div>
								<div css={css`grid-row:1/4;grid-column:2/4;`}>
									<div css={[typographyAlignCenter, listHead, css`height:162px;`]}>
										<span css={typographyBlock}>
											通院日額
										</span>
										<Dropdown>
											<Dropdown.Toggle variant="dropdown" css={listHeadDropdown}>指定なし</Dropdown.Toggle>
											<Dropdown.Menu>
												<Dropdown.Item>指定なし</Dropdown.Item>
												<Dropdown.Item>指定なし</Dropdown.Item>
												<Dropdown.Item>指定なし</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									</div>
									<div css={[typographyAlignCenter, listHead, css`height:100px;`]}>
										<span css={typographyBlock}>
											通院日額
										</span>
										<Dropdown>
											<Dropdown.Toggle variant="dropdown" css={listHeadDropdown}>指定なし</Dropdown.Toggle>
											<Dropdown.Menu>
												<Dropdown.Item>指定なし</Dropdown.Item>
												<Dropdown.Item>指定なし</Dropdown.Item>
												<Dropdown.Item>指定なし</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									</div>
									<div css={[typographyAlignCenter, listHead, css`height:100px;`]}>
										<span css={typographyBlock}>
											通院一時金
										</span>
										<Dropdown>
											<Dropdown.Toggle variant="dropdown" css={listHeadDropdown}>指定なし</Dropdown.Toggle>
											<Dropdown.Menu>
												<Dropdown.Item>指定なし</Dropdown.Item>
												<Dropdown.Item>指定なし</Dropdown.Item>
												<Dropdown.Item>指定なし</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									</div>
								</div>
							</div>
							<div css={[typographyAlignCenter, listHead, css`height:100px;`]}>
								<span css={typographyBlock}>
									先進医療
								</span>
								<Dropdown>
									<Dropdown.Toggle variant="dropdown" css={listHeadDropdown}>指定なし</Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item>指定なし</Dropdown.Item>
										<Dropdown.Item>指定なし</Dropdown.Item>
										<Dropdown.Item>指定なし</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div css={[typographyAlignCenter, listHead, css`height:100px;`]}>
								<span css={typographyBlock}>
									がん特約
								</span>
								<Dropdown>
									<Dropdown.Toggle variant="dropdown" css={listHeadDropdown}>指定なし</Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item>指定なし</Dropdown.Item>
										<Dropdown.Item>指定なし</Dropdown.Item>
										<Dropdown.Item>指定なし</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div css={[typographyAlignCenter, listHead, css`height:100px;`]}>
								<span css={typographyBlock}>
									放射線治療
								</span>
								<Dropdown>
									<Dropdown.Toggle variant="dropdown" css={listHeadDropdown}>指定なし</Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item>指定なし</Dropdown.Item>
										<Dropdown.Item>指定なし</Dropdown.Item>
										<Dropdown.Item>指定なし</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div css={[typographyAlignCenter, listHead, css`height:100px;`]}>
								<span css={typographyBlock}>
									抗がん剤治療
								</span>
								<Dropdown>
									<Dropdown.Toggle variant="dropdown" css={listHeadDropdown}>指定なし</Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item>指定なし</Dropdown.Item>
										<Dropdown.Item>指定なし</Dropdown.Item>
										<Dropdown.Item>指定なし</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div css={[typographyAlignCenter, listHead, css`height:100px;`]}>
								<span css={typographyBlock}>
									三大疾病
								</span>
								<Dropdown>
									<Dropdown.Toggle variant="dropdown" css={listHeadDropdown}>指定なし</Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item>指定なし</Dropdown.Item>
										<Dropdown.Item>指定なし</Dropdown.Item>
										<Dropdown.Item>指定なし</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div css={[typographyAlignCenter, listHead, css`height:100px;`]}>
								<span css={typographyBlock}>
									生活習慣病
								</span>
								<Dropdown>
									<Dropdown.Toggle variant="dropdown" css={listHeadDropdown}>指定なし</Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item>指定なし</Dropdown.Item>
										<Dropdown.Item>指定なし</Dropdown.Item>
										<Dropdown.Item>指定なし</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>

							</div>
							<div css={[typographyAlignCenter, listHead, css`height:100px;`]}>
								<span css={typographyBlock}>
									女性特約
								</span>
								<Dropdown>
									<Dropdown.Toggle variant="dropdown" css={listHeadDropdown}>指定なし</Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item>指定なし</Dropdown.Item>
										<Dropdown.Item>指定なし</Dropdown.Item>
										<Dropdown.Item>指定なし</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div css={[typographyAlignCenter, listHead, css`height:100px;`]}>
								<span css={typographyBlock}>
									感染症特約
								</span>
								<Dropdown>
									<Dropdown.Toggle variant="dropdown" css={listHeadDropdown}>指定なし</Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item>指定なし</Dropdown.Item>
										<Dropdown.Item>指定なし</Dropdown.Item>
										<Dropdown.Item>指定なし</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div css={[typographyAlignCenter, listHead, css`height:100px;`]}>
								<span css={typographyBlock}>
									死亡特約
								</span>
								<Dropdown>
									<Dropdown.Toggle variant="dropdown" css={listHeadDropdown}>指定なし</Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item>指定なし</Dropdown.Item>
										<Dropdown.Item>指定なし</Dropdown.Item>
										<Dropdown.Item>指定なし</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div css={[typographyAlignCenter, listHead, css`height:100px;`]}>
								<span css={typographyBlock}>
									健康ボーナス<br />（お祝い金）
								</span>
								<Dropdown>
									<Dropdown.Toggle variant="dropdown" css={listHeadDropdown}>指定なし</Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item>指定なし</Dropdown.Item>
										<Dropdown.Item>指定なし</Dropdown.Item>
										<Dropdown.Item>指定なし</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div css={[typographyAlignCenter, listHead, css`height:100px;`]}>
								<span css={typographyBlock}>
									保険料振込免除
								</span>
								<Dropdown>
									<Dropdown.Toggle variant="dropdown" css={listHeadDropdown}>指定なし</Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item>指定なし</Dropdown.Item>
										<Dropdown.Item>指定なし</Dropdown.Item>
										<Dropdown.Item>指定なし</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div css={[typographyAlignCenter, listHead, css`height:100px;`]}>
								<span css={typographyBlock}>
									引受基準緩和型
								</span>
								<Dropdown>
									<Dropdown.Toggle variant="dropdown" css={listHeadDropdown}>指定なし</Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item>指定なし</Dropdown.Item>
										<Dropdown.Item>指定なし</Dropdown.Item>
										<Dropdown.Item>指定なし</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
						{/* 製品 */}
						<div css={css`
							display:flex;
							flex-flow:row;
							overflow-x: scroll;
							.wrap{
								flex-shrink: 0;
							}
						`}>

							<div css={css`width: 180px;`} className="wrap">
								<div css={[radioBtn, css`
								height: 55px;
								display: flex;
								justify-content: center;
								align-items: center;
								`]}>
									<Form.Check type="radio">
									</Form.Check>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:50px;`]}>
									<span css={typographyBlock}>
										1位
									</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:102px;`]}>
									<span>保険会社名</span>
									<Link to="/comparison/single/">
										<img src="/img/single_icon.png" alt="icon" width={118} height={46} css={css`object-fit: contain;`} />
									</Link>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:50px;`]}>
									<span>¥2,330</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:75px;`]}>
									<Button variant='orange' css={netBtn}>ネット申し込み</Button>
									<a href="" css={typographyPrimaryColor}>FBに相談する▶︎</a>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:60px;`]}>
									<span css={addButton}>＋</span>
								</div>
								<div css={[listBody, css`font-size:12px;height:162px;`]}>
									<span>1,000円,2,000円,3,000円,4,000円,5,000円,6,000円,7,000円,8,000円,9,000円,10,000円,11,000円,12,00円,13,000円,14,00円,15,00円,16,000円,17,00円,18,00円,19,000円,20,000円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>30日,60日,120日,無制限（特定疾病特約含む）</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>10倍,20倍,60倍</span>
								</div>
								<div css={[listBody, css`font-size:12px;height:162px;`]}>
									<span>1,000円,2,000円,3,000円,4,000円,5,000円,6,000円,7,000円,8,000円,9,000円,10,000円,11,000円,12,00円,13,000円,14,00円,15,00円,16,000円,17,00円,18,00円,19,000円,20,000円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>30日,60日,120日,無制限（特定疾病特約含む）</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,3,000円,5,000円,10,000円,入院日額×0.5倍,入院日額×1倍</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>-</span>
								</div>
							</div>

							{/* ========== 複製ここから ========== */}
							<div css={css`width: 180px;`} className="wrap">
								<div css={[radioBtn, css`
								height: 55px;
								display: flex;
								justify-content: center;
								align-items: center;
								`]}>
									<Form.Check type="radio">
									</Form.Check>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:50px;`]}>
									<span css={typographyBlock}>
										2位
									</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:102px;`]}>
									<span>保険会社名</span>
									<Link to="/comparison/single/">
										<img src="/img/single_icon.png" alt="icon" width={118} height={46} css={css`object-fit: contain;`} />
									</Link>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:50px;`]}>
									<span>¥2,330</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:75px;`]}>
									<Button variant='orange' css={netBtn}>ネット申し込み</Button>
									<a href="" css={typographyPrimaryColor}>FBに相談する▶︎</a>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:60px;`]}>
									<span css={addButton}>＋</span>
								</div>
								<div css={[listBody, css`font-size:12px;height:162px;`]}>
									<span>1,000円,2,000円,3,000円,4,000円,5,000円,6,000円,7,000円,8,000円,9,000円,10,000円,11,000円,12,00円,13,000円,14,00円,15,00円,16,000円,17,00円,18,00円,19,000円,20,000円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>30日,60日,120日,無制限（特定疾病特約含む）</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>10倍,20倍,60倍</span>
								</div>
								<div css={[listBody, css`font-size:12px;height:162px;`]}>
									<span>1,000円,2,000円,3,000円,4,000円,5,000円,6,000円,7,000円,8,000円,9,000円,10,000円,11,000円,12,00円,13,000円,14,00円,15,00円,16,000円,17,00円,18,00円,19,000円,20,000円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>30日,60日,120日,無制限（特定疾病特約含む）</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,3,000円,5,000円,10,000円,入院日額×0.5倍,入院日額×1倍</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>-</span>
								</div>
							</div>
							<div css={css`width: 180px;`} className="wrap">
								<div css={[radioBtn, css`
								height: 55px;
								display: flex;
								justify-content: center;
								align-items: center;
								`]}>
									<Form.Check type="radio">
									</Form.Check>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:50px;`]}>
									<span css={typographyBlock}>
										3位
									</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:102px;`]}>
									<span>保険会社名</span>
									<Link to="/comparison/single/">
										<img src="/img/single_icon.png" alt="icon" width={118} height={46} css={css`object-fit: contain;`} />
									</Link>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:50px;`]}>
									<span>¥2,330</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:75px;`]}>
									<Button variant='orange' css={netBtn}>ネット申し込み</Button>
									<a href="" css={typographyPrimaryColor}>FBに相談する▶︎</a>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:60px;`]}>
									<span css={addButton}>＋</span>
								</div>
								<div css={[listBody, css`font-size:12px;height:162px;`]}>
									<span>1,000円,2,000円,3,000円,4,000円,5,000円,6,000円,7,000円,8,000円,9,000円,10,000円,11,000円,12,00円,13,000円,14,00円,15,00円,16,000円,17,00円,18,00円,19,000円,20,000円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>30日,60日,120日,無制限（特定疾病特約含む）</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>10倍,20倍,60倍</span>
								</div>
								<div css={[listBody, css`font-size:12px;height:162px;`]}>
									<span>1,000円,2,000円,3,000円,4,000円,5,000円,6,000円,7,000円,8,000円,9,000円,10,000円,11,000円,12,00円,13,000円,14,00円,15,00円,16,000円,17,00円,18,00円,19,000円,20,000円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>30日,60日,120日,無制限（特定疾病特約含む）</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,3,000円,5,000円,10,000円,入院日額×0.5倍,入院日額×1倍</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>-</span>
								</div>
							</div>
							<div css={css`width: 180px;`} className="wrap">
								<div css={[radioBtn, css`
								height: 55px;
								display: flex;
								justify-content: center;
								align-items: center;
								`]}>
									<Form.Check type="radio">
									</Form.Check>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:50px;`]}>
									<span css={typographyBlock}>
										4位
									</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:102px;`]}>
									<span>保険会社名</span>
									<Link to="/comparison/single/">
										<img src="/img/single_icon.png" alt="icon" width={118} height={46} css={css`object-fit: contain;`} />
									</Link>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:50px;`]}>
									<span>¥2,330</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:75px;`]}>
									<Button variant='orange' css={netBtn}>ネット申し込み</Button>
									<a href="" css={typographyPrimaryColor}>FBに相談する▶︎</a>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:60px;`]}>
									<span css={addButton}>＋</span>
								</div>
								<div css={[listBody, css`font-size:12px;height:162px;`]}>
									<span>1,000円,2,000円,3,000円,4,000円,5,000円,6,000円,7,000円,8,000円,9,000円,10,000円,11,000円,12,00円,13,000円,14,00円,15,00円,16,000円,17,00円,18,00円,19,000円,20,000円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>30日,60日,120日,無制限（特定疾病特約含む）</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>10倍,20倍,60倍</span>
								</div>
								<div css={[listBody, css`font-size:12px;height:162px;`]}>
									<span>1,000円,2,000円,3,000円,4,000円,5,000円,6,000円,7,000円,8,000円,9,000円,10,000円,11,000円,12,00円,13,000円,14,00円,15,00円,16,000円,17,00円,18,00円,19,000円,20,000円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>30日,60日,120日,無制限（特定疾病特約含む）</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,3,000円,5,000円,10,000円,入院日額×0.5倍,入院日額×1倍</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>-</span>
								</div>
							</div>
							<div css={css`width: 180px;`} className="wrap">
								<div css={[radioBtn, css`
								height: 55px;
								display: flex;
								justify-content: center;
								align-items: center;
								`]}>
									<Form.Check type="radio">
									</Form.Check>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:50px;`]}>
									<span css={typographyBlock}>
										5位
									</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:102px;`]}>
									<span>保険会社名</span>
									<Link to="/comparison/single/">
										<img src="/img/single_icon.png" alt="icon" width={118} height={46} css={css`object-fit: contain;`} />
									</Link>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:50px;`]}>
									<span>¥2,330</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:75px;`]}>
									<Button variant='orange' css={netBtn}>ネット申し込み</Button>
									<a href="" css={typographyPrimaryColor}>FBに相談する▶︎</a>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:60px;`]}>
									<span css={addButton}>＋</span>
								</div>
								<div css={[listBody, css`font-size:12px;height:162px;`]}>
									<span>1,000円,2,000円,3,000円,4,000円,5,000円,6,000円,7,000円,8,000円,9,000円,10,000円,11,000円,12,00円,13,000円,14,00円,15,00円,16,000円,17,00円,18,00円,19,000円,20,000円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>30日,60日,120日,無制限（特定疾病特約含む）</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>10倍,20倍,60倍</span>
								</div>
								<div css={[listBody, css`font-size:12px;height:162px;`]}>
									<span>1,000円,2,000円,3,000円,4,000円,5,000円,6,000円,7,000円,8,000円,9,000円,10,000円,11,000円,12,00円,13,000円,14,00円,15,00円,16,000円,17,00円,18,00円,19,000円,20,000円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>30日,60日,120日,無制限（特定疾病特約含む）</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,3,000円,5,000円,10,000円,入院日額×0.5倍,入院日額×1倍</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>-</span>
								</div>
							</div>
							<div css={css`width: 180px;`} className="wrap">
								<div css={[radioBtn, css`
								height: 55px;
								display: flex;
								justify-content: center;
								align-items: center;
								`]}>
									<Form.Check type="radio">
									</Form.Check>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:50px;`]}>
									<span css={typographyBlock}>
										6位
									</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:102px;`]}>
									<span>保険会社名</span>
									<Link to="/comparison/single/">
										<img src="/img/single_icon.png" alt="icon" width={118} height={46} css={css`object-fit: contain;`} />
									</Link>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:50px;`]}>
									<span>¥2,330</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:75px;`]}>
									<Button variant='orange' css={netBtn}>ネット申し込み</Button>
									<a href="" css={typographyPrimaryColor}>FBに相談する▶︎</a>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:60px;`]}>
									<span css={addButton}>＋</span>
								</div>
								<div css={[listBody, css`font-size:12px;height:162px;`]}>
									<span>1,000円,2,000円,3,000円,4,000円,5,000円,6,000円,7,000円,8,000円,9,000円,10,000円,11,000円,12,00円,13,000円,14,00円,15,00円,16,000円,17,00円,18,00円,19,000円,20,000円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>30日,60日,120日,無制限（特定疾病特約含む）</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>10倍,20倍,60倍</span>
								</div>
								<div css={[listBody, css`font-size:12px;height:162px;`]}>
									<span>1,000円,2,000円,3,000円,4,000円,5,000円,6,000円,7,000円,8,000円,9,000円,10,000円,11,000円,12,00円,13,000円,14,00円,15,00円,16,000円,17,00円,18,00円,19,000円,20,000円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>30日,60日,120日,無制限（特定疾病特約含む）</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,3,000円,5,000円,10,000円,入院日額×0.5倍,入院日額×1倍</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>-</span>
								</div>
							</div>
							<div css={css`width: 180px;`} className="wrap">
								<div css={[radioBtn, css`
								height: 55px;
								display: flex;
								justify-content: center;
								align-items: center;
								`]}>
									<Form.Check type="radio">
									</Form.Check>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:50px;`]}>
									<span css={typographyBlock}>
										7位
									</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:102px;`]}>
									<span>保険会社名</span>
									<Link to="/comparison/single/">
										<img src="/img/single_icon.png" alt="icon" width={118} height={46} css={css`object-fit: contain;`} />
									</Link>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:50px;`]}>
									<span>¥2,330</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:75px;`]}>
									<Button variant='orange' css={netBtn}>ネット申し込み</Button>
									<a href="" css={typographyPrimaryColor}>FBに相談する▶︎</a>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:60px;`]}>
									<span css={addButton}>＋</span>
								</div>
								<div css={[listBody, css`font-size:12px;height:162px;`]}>
									<span>1,000円,2,000円,3,000円,4,000円,5,000円,6,000円,7,000円,8,000円,9,000円,10,000円,11,000円,12,00円,13,000円,14,00円,15,00円,16,000円,17,00円,18,00円,19,000円,20,000円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>30日,60日,120日,無制限（特定疾病特約含む）</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>10倍,20倍,60倍</span>
								</div>
								<div css={[listBody, css`font-size:12px;height:162px;`]}>
									<span>1,000円,2,000円,3,000円,4,000円,5,000円,6,000円,7,000円,8,000円,9,000円,10,000円,11,000円,12,00円,13,000円,14,00円,15,00円,16,000円,17,00円,18,00円,19,000円,20,000円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>30日,60日,120日,無制限（特定疾病特約含む）</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,3,000円,5,000円,10,000円,入院日額×0.5倍,入院日額×1倍</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>なし,5万円,10万円,15万円,20万円</span>
								</div>
								<div css={[typographyAlignCenter, listBody, css`height:100px;`]}>
									<span>-</span>
								</div>
							</div>
							{/* ========== 複製ここまで ========== */}

						</div>
					</div>
				</section>
				{/* 商品表 PC ここまで*/}
				<section css={css`margin-top: 70px;display:flex;column-gap:15px;`}>
					<Button variant='primary'>登録</Button>
					<Button variant='secondary'>キャンセル</Button>
				</section>
			</section>
	)
});

const buttongroupWrap = css`
	display: grid;
	button{
		padding: 17px 0;
	}
`
const CategoryTitleMargin = css`
	margin-bottom: 36px;
`
const buttongroupWrapMargin = css`
	margin-bottom: 38px;
`
const buttongroupWrapColumns = (column: number, tabColumn: number, spColumn: number) => css`
	grid-template-columns: repeat(${column},1fr);
	@media(max-width: 992px){
		grid-template-columns: repeat(${tabColumn},1fr);
	}
	@media(max-width: 576px){
		grid-template-columns: repeat(${spColumn},1fr);
	}
`
const radioBtn = css`
	background:	#e7effe;
`
const dropDownTitle = css`
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 12px;
`
const listHead = css`
	border: 1px solid #000;
	background: #E0E0E0;
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
`
const listHeadDropdown = css`
	font-size:14px;
	padding:6px 18px 6px;
	font-weight: 600;
`
const listBody = css`
	padding: 5px;
	border: 1px solid #000;
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
	font-feature-settings: "palt";
	a{
		text-decoration: underline;
	}
`
const addButton = css`
	background: #00153e;
	border-radius: 50%;
	color: #fff;
	font-weight: 600;
	aspect-ratio: 1/1;
`
const addIcon = css`
	padding: 10px 50px;
	position: relative;
	&:after{
		content:'＋';
		color: #f60;
		background: #fff;
		border-radius: 50%;
		position: absolute;
		width: 24px;
		height: 24px;
		right: 1em;
	}
`


const netBtn = css`
	font-size: 14px;
	font-weight: 600;
	padding: 11px 29px 11px 13px;
	border-radius: 50px;
	line-height: 1;
	position: relative;
	&:after{
		content: '';
		position: absolute;
		padding: 9px;
		background: url('/img/netbtn_icon.svg') no-repeat;
		background-position: center;
		background-size: 1em;
		top: 50%;
		transform: translateY(-50%);
	}
`

export const typographyBlock = css`
    display: block;
`
export const typographyAlignCenter = css`
    text-align: center;
`
export const typographyPrimaryColor = css`
    color: #00153e;
`
export const typographySecondaryColor = css`
    color: #5e5e5e;
`
export const typographyComparisonCategoryTitle = css`
    font-size: 20px;
    font-weight: 600;
    line-height: 1;
`