/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FC, memo } from "react";
import { Button, Col, Form, Pagination, Row, Stack, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { SubTitle } from "../atoms/typography/SubTitle";
import { WrapperDefaultLayout } from "../template/WrapperDefaultLayout";


export const ProductSearch: FC = memo(() => { 
    return (
        <WrapperDefaultLayout>
            <SubTitle>保険データ一覧</SubTitle>
            <section>
                <p className="fw-bold" css={css`font-size: min(1.25rem + 1.5vw,24px);`}>保険商材検索</p>
                <Form className="py-2">
                    <Row className="mb-4 gap-5">
                        <Form.Group as={Col} controlId="formSearchCompany">
                            <Form.Label>保険会社</Form.Label>
                            <Form.Control />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formSearchAge">
                            <Form.Label>加入年齢</Form.Label>
                            <Form.Control />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formSearchFa">
                            <Form.Label>FA販売</Form.Label>
                            <Form.Control />
                        </Form.Group>
                    </Row>
                    <Row className="mb-4 gap-5">
                        <Form.Group as={Col} controlId="formSearchCompany">
                            <Form.Label>割引</Form.Label>
                            <Form.Control />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formSearchAge">
                            <Form.Label>商品名</Form.Label>
                            <Form.Control />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formSearchFa">
                            <Form.Label>特約・特則</Form.Label>
                            <Form.Control />
                        </Form.Group>
                    </Row>
                    <Row className="mb-4 gap-5">
                        <Form.Group as={Col} controlId="formSearchCompany">
                            <Form.Label>振込方法</Form.Label>
                            <Form.Control />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formSearchAge">
                            <Form.Label>付帯サービス</Form.Label>
                            <Form.Control />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formSearchFa">
                            <Form.Label>保険期間</Form.Label>
                            <Form.Control />
                        </Form.Group>
                    </Row>
                    <Row className="mb-4 gap-5">
                        <Form.Group as={Col} controlId="formSearchCompany">
                            <Form.Label>注意書き</Form.Label>
                            <Form.Control />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formSearchAge">
                            <Form.Label>保険料振込期間</Form.Label>
                            <Form.Control />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formSearchFa">
                            <Form.Label>特徴(タグ)</Form.Label>
                            <Form.Control />
                        </Form.Group>
                    </Row>
                    <Row className="mb-4 gap-5">
                        <Form.Group as={Col} controlId="formSearchCompany">
                            <Form.Label>申込方法</Form.Label>
                            <Form.Control />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formSearchAge">
                            <Form.Label>死亡補償額</Form.Label>
                            <Form.Control />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formSearchFa">
                            <Form.Label>支払い方法</Form.Label>
                            <Form.Control />
                        </Form.Group>
                    </Row>
                    <Row className="mb-4 gap-5">
                        <Form.Group as={Col} controlId="formSearchCompany">
                            <Form.Label>更新日</Form.Label>
                            <Form.Control />
                        </Form.Group>
                        <Col />
                        <Col />
                    </Row>
                    <Stack direction="horizontal" gap={3} className="mt-5 justify-content-center">
                        <Button variant="primary">検索</Button>
                    </Stack>
                </Form>
            </section>
            <section className="my-5">
                <Stack direction="horizontal" gap={3} className="pt-4 justify-content-between">
                    <p className="fw-bold" css={css`font-size: min(1.25rem + 1.5vw,24px);`}>検索結果一覧</p>
                    <Button variant="link" className="text-dark">全商品CSVダウンロード</Button>
                </Stack>
                <Table bordered className="my-2 bg-light">
                    <thead>
                        <tr>
                            <th>登録ID</th>
                            <th>画像</th>
                            <th colSpan={2}>保険名</th>
                            <th>編集</th>
                            <th>削除</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>001</td>
                            <td>あり</td>
                            <td colSpan={2}>オリックス生命</td>
                            <td><Link to="/product"><Button variant="link" className="p-0 text-dark">編集</Button></Link></td>
                            <td><Button variant="link" className="p-0 text-dark">削除</Button></td>
                        </tr>
                    </tbody>
                </Table>
                <Pagination className="mt-5 justify-content-center">
                    <Pagination.Prev disabled>Back</Pagination.Prev>
                    <Pagination.Item active>{1}</Pagination.Item>
                    <Pagination.Item>{2}</Pagination.Item>
                    <Pagination.Item>{3}</Pagination.Item>
                    <Pagination.Next>Next</Pagination.Next>
                </Pagination>
            </section>
        </WrapperDefaultLayout>
    );
});