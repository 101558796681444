/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FC, memo } from "react";
import { Button, Stack, Container, Row, Col, Form, InputGroup } from "react-bootstrap";

export const SettingPopup: FC = memo(() => {
	return (
		<div css={popupWrapper} className="accountpopup px-5 py-4">
			<Stack gap={3}>
				<span className="fs-4">診断ページ編集</span>
				<div className="mb-2">
						<span>提携先名</span>
						<InputGroup className="gap-2">
							<Form.Control
								placeholder=""
								aria-label="partnerName"
								aria-describedby="partnerName"
							/>
						</InputGroup>
					</div>
				<Container>
					<Row className="d-flex align-items-center mb-3">
						<Col>メインカラー</Col>
						<Col className="d-flex gap-4">
							<Form.Control
								type="color"
								id="mainColorInput"
								defaultValue="#0d6efd"
								title="Choose your color"
								css={css`
									padding: 0%;
									border: none;
									border-radius: 0;
									width: 40px;
									height: 40px;
									background-color: transparent;
								`}
							/>
							<Form.Label htmlFor="mainColorInput">
								<span className="btn btn-outline-primary">色選択</span>
							</Form.Label>
						</Col>
					</Row>

					<Row className="d-flex align-items-center mb-3">
						<Col>サブカラー</Col>
						<Col className="d-flex gap-4">
							<Form.Control
								type="color"
								id="subColorInput"
								defaultValue="#ffffff"
								title="Choose your color"
								css={css`
									padding: 0%;
									border: none;
									border-radius: 0;
									width: 40px;
									height: 40px;
									background-color: transparent;
								`}
							/>
							<Form.Label htmlFor="subColorInput">
								<span className="btn btn-outline-primary">色選択</span>
							</Form.Label>
						</Col>
					</Row>

					<Row className="d-flex align-items-center mb-3">
						<Col>サブカラー2</Col>
						<Col className="d-flex gap-4">
							<Form.Control
								type="color"
								id="subColor2Input"
								defaultValue="#DBEAED"
								title="Choose your color"
								css={css`
									padding: 0%;
									border: none;
									border-radius: 0;
									width: 40px;
									height: 40px;
									background-color: transparent;
								`}
							/>
							<Form.Label htmlFor="subColor2Input">
								<span className="btn btn-outline-primary">色選択</span>
							</Form.Label>
						</Col>
					</Row>

					<Row className="d-flex align-items-center mb-3">
						<Col>サブカラー3</Col>
						<Col className="d-flex gap-4">
							<Form.Control
								type="color"
								id="subColor3Input"
								defaultValue="#C6D7D8"
								title="Choose your color"
								css={css`
									padding: 0%;
									border: none;
									border-radius: 0;
									width: 40px;
									height: 40px;
									background-color: transparent;
								`}
							/>
							<Form.Label htmlFor="subColor3Input">
								<span className="btn btn-outline-primary">色選択</span>
							</Form.Label>
						</Col>
					</Row>

					<Row className="d-flex align-items-center mb-3">
						<Col>フォントカラー</Col>
						<Col className="d-flex gap-4">
							<Form.Control
								type="color"
								id="fontColorInput"
								defaultValue="#00153E"
								title="Choose your color"
								css={css`
									padding: 0%;
									border: none;
									border-radius: 0;
									width: 40px;
									height: 40px;
									background-color: transparent;
								`}
							/>
							<Form.Label htmlFor="fontColorInput">
								<span className="btn btn-outline-primary">色選択</span>
							</Form.Label>
						</Col>
					</Row>

					<Row className="d-flex align-items-center mb-3">
						<Col>背景カラー</Col>
						<Col className="d-flex gap-4">
							<Form.Control
								type="color"
								id="backgroundColorInput"
								defaultValue="#EEF5F5"
								title="Choose your color"
								css={css`
									padding: 0%;
									border: none;
									border-radius: 0;
									width: 40px;
									height: 40px;
									background-color: transparent;
								`}
							/>
							<Form.Label htmlFor="backgroundColorInput">
								<span className="btn btn-outline-primary">色選択</span>
							</Form.Label>
						</Col>
					</Row>
					
					<Row className="d-flex align-items-center mb-3">
						<Col>メインボタンカラー</Col>
						<Col className="d-flex gap-4">
							<Form.Control
								type="color"
								id="mainBtnColorInput"
								defaultValue="#002A83"
								title="Choose your color"
								css={css`
									padding: 0%;
									border: none;
									border-radius: 0;
									width: 40px;
									height: 40px;
									background-color: transparent;
								`}
							/>
							<Form.Label htmlFor="mainBtnColorInput">
								<span className="btn btn-outline-primary">色選択</span>
							</Form.Label>
						</Col>
					</Row>

					<Row className="d-flex align-items-center mb-3">
						<Col>診断結果保存</Col>
						<Col className="d-flex gap-4">
							<Form.Control
								type="color"
								id="resultColorInput"
								defaultValue="#EF7B33"
								title="Choose your color"
								css={css`
									padding: 0%;
									border: none;
									border-radius: 0;
									width: 40px;
									height: 40px;
									background-color: transparent;
								`}
							/>
							<Form.Label htmlFor="resultColorInput">
								<span className="btn btn-outline-primary">色選択</span>
							</Form.Label>
						</Col>
					</Row>

					<Row className="d-flex align-items-center mb-3">
						<Col>資料請求</Col>
						<Col className="d-flex gap-4">
							<Form.Control
								type="color"
								id="documentColorInput"
								defaultValue="#535353"
								title="Choose your color"
								css={css`
									padding: 0%;
									border: none;
									border-radius: 0;
									width: 40px;
									height: 40px;
									background-color: transparent;
								`}
							/>
							<Form.Label htmlFor="documentColorInput">
								<span className="btn btn-outline-primary">色選択</span>
							</Form.Label>
						</Col>
					</Row>

					<Row className="d-flex align-items-center mb-3">
						<Col>FP相談</Col>
						<Col className="d-flex gap-4">
							<Form.Control
								type="color"
								id="fpColorInput"
								defaultValue="#535353"
								title="Choose your color"
								css={css`
									padding: 0%;
									border: none;
									border-radius: 0;
									width: 40px;
									height: 40px;
									background-color: transparent;
								`}
							/>
							<Form.Label htmlFor="fpColorInput">
								<span className="btn btn-outline-primary">色選択</span>
							</Form.Label>
						</Col>
					</Row>

					<Row className="d-flex align-items-center mb-3">
						<Col>比較表メインカラー</Col>
						<Col className="d-flex gap-4">
							<Form.Control
								type="color"
								id="comparisonMainColorInput"
								defaultValue="#102C6A"
								title="Choose your color"
								css={css`
									padding: 0%;
									border: none;
									border-radius: 0;
									width: 40px;
									height: 40px;
									background-color: transparent;
								`}
							/>
							<Form.Label htmlFor="comparisonMainColorInput">
								<span className="btn btn-outline-primary">色選択</span>
							</Form.Label>
						</Col>
					</Row>

					<Row className="d-flex align-items-center mb-3">
						<Col>比較表サブカラー</Col>
						<Col className="d-flex gap-4">
							<Form.Control
								type="color"
								id="comparisonSubColorInput"
								defaultValue="#003584"
								title="Choose your color"
								css={css`
									padding: 0%;
									border: none;
									border-radius: 0;
									width: 40px;
									height: 40px;
									background-color: transparent;
								`}
							/>
							<Form.Label htmlFor="comparisonSubColorInput">
								<span className="btn btn-outline-primary">色選択</span>
							</Form.Label>
						</Col>
					</Row>

				</Container>
				<Stack gap={4}>
					<div>
						<span>資料請求ボタン</span>
						<InputGroup className="gap-2">
							<Form.Check
								type="radio"
								label="表示"
								name="download"
								id="download-true"
							/>
							<Form.Check
								type="radio"
								label="非表示"
								name="download"
								id="download-false"
							/>
						</InputGroup>
					</div>

					<div>
						<span>診断結果ボタン</span>
						<InputGroup className="gap-2">
							<Form.Check
								type="radio"
								label="表示"
								name="result"
								id="result-true"
							/>
							<Form.Check
								type="radio"
								label="非表示"
								name="result"
								id="result-false"
							/>
						</InputGroup>
					</div>

					<div>
						<span>FP相談ボタン</span>
						<InputGroup className="gap-2">
							<Form.Check
								type="radio"
								label="表示"
								name="fp"
								id="fp-true"
							/>
							<Form.Check
								type="radio"
								label="非表示"
								name="fp"
								id="fp-false"
							/>
						</InputGroup>
					</div>

					<div>
						<span>保険商品比較ボタン</span>
						<InputGroup className="gap-2">
							<Form.Check
								type="radio"
								label="表示"
								name="comparison"
								id="comparison-true"
							/>
							<Form.Check
								type="radio"
								label="非表示"
								name="comparison"
								id="comparison-false"
							/>
						</InputGroup>
					</div>

					<div>
						<span>提携先パラメータ記入欄</span>
						<InputGroup className="gap-2">
							<Form.Control
								placeholder="ここにパラメータが入ります"
								aria-label="param"
								aria-describedby="param"
							/>
						</InputGroup>
					</div>
					<div>
						<span>流入元パラメータ記入欄</span>
						<InputGroup className="gap-2 mb-2">
							<Form.Control
								placeholder="ここにパラメータが入ります"
								aria-label="param"
								aria-describedby="param"
							/>
						</InputGroup>

						<InputGroup className="gap-2 mb-2">
							<Form.Control
								placeholder="ここにパラメータが入ります"
								aria-label="param"
								aria-describedby="param"
							/>
						</InputGroup>
						<InputGroup className="gap-2 mb-2">
							<Form.Control
								placeholder="ここにパラメータが入ります"
								aria-label="param"
								aria-describedby="param"
							/>
						</InputGroup>
						<InputGroup className="gap-2 mb-2">
							<Form.Control
								placeholder="ここにパラメータが入ります"
								aria-label="param"
								aria-describedby="param"
							/>
						</InputGroup>
						<InputGroup className="gap-2 mb-2">
							<Form.Control
								placeholder="ここにパラメータが入ります"
								aria-label="param"
								aria-describedby="param"
							/>
						</InputGroup>
					</div>

				</Stack>
			</Stack>
			<div className="my-4 d-flex justify-content-center gap-4">
				<Button variant="primary">保存する</Button>
			</div>
		</div>
	);
});

const popupWrapper = css`
max-width: 500px;
width: 100%;
max-height: 640px;
height: 100%;
overflow-y: scroll;
background: #eaecef;
`