/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FC, memo } from "react";
import { useState } from "react";
import { Button, Stack, Container, Row, Col } from "react-bootstrap";
import { WrapperDefaultLayout } from "../template/WrapperDefaultLayout";
import { SubTitle } from "../atoms/typography/SubTitle";

import { AccountPopup } from "../atoms/AccountPopup";
export const AccountControl: FC = memo(() => {
	const [accountPopup, setAccountPopup] = useState<boolean>(false);
	return (
		<>
		<WrapperDefaultLayout>
			<SubTitle>アカウント管理</SubTitle>
			<Stack gap={5}>
				<div className="d-flex flex-row justify-content-end gap-3">
					<Button variant="success">新規追加</Button>
					<Button variant="secondary">一括削除</Button>
				</div>
				<section>
					<Container className="p-0">
						<Row className="mb-4">
							<Col bsPrefix="col-1">
								<input type="checkbox" name="checked" className="form-check-input" />
							</Col>
							<Col bsPrefix="col-1">
								<span>No.xxx</span>
							</Col>
							<Col bsPrefix="col-2">
								<span>テスト中山</span>
							</Col>
							<Col bsPrefix="col-6">
								<span>xxxx@financial-agency.com</span>
							</Col>
							<Col bsPrefix="col-2">
								<div className="d-flex justify-content-end gap-3">
									<Button variant="primary" onClick={() => setAccountPopup(true)}>編集</Button>
									<Button variant="danger">削除</Button>
								</div>
							</Col>
						</Row>

						<Row className="mb-4">
							<Col bsPrefix="col-1">
								<input type="checkbox" name="checked" className="form-check-input" />
							</Col>
							<Col bsPrefix="col-1">
								<span>No.xxx</span>
							</Col>
							<Col bsPrefix="col-2">
								<span>テスト中山</span>
							</Col>
							<Col bsPrefix="col-6">
								<span>xxxx@financial-agency.com</span>
							</Col>
							<Col bsPrefix="col-2">
								<div className="d-flex justify-content-end gap-3">
									<Button variant="primary" onClick={() => setAccountPopup(true)}>編集</Button>
									<Button variant="danger">削除</Button>
								</div>
							</Col>
						</Row>
						<Row className="mb-4">
							<Col bsPrefix="col-1">
								<input type="checkbox" name="checked" className="form-check-input" />
							</Col>
							<Col bsPrefix="col-1">
								<span>No.xxx</span>
							</Col>
							<Col bsPrefix="col-2">
								<span>テスト中山</span>
							</Col>
							<Col bsPrefix="col-6">
								<span>xxxx@financial-agency.com</span>
							</Col>
							<Col bsPrefix="col-2">
								<div className="d-flex justify-content-end gap-3">
									<Button variant="primary" onClick={() => setAccountPopup(true)}>編集</Button>
									<Button variant="danger">削除</Button>
								</div>
							</Col>
						</Row>
						<Row className="mb-4">
							<Col bsPrefix="col-1">
								<input type="checkbox" name="checked" className="form-check-input" />
							</Col>
							<Col bsPrefix="col-1">
								<span>No.xxx</span>
							</Col>
							<Col bsPrefix="col-2">
								<span>テスト中山</span>
							</Col>
							<Col bsPrefix="col-6">
								<span>xxxx@financial-agency.com</span>
							</Col>
							<Col bsPrefix="col-2">
								<div className="d-flex justify-content-end gap-3">
									<Button variant="primary" onClick={() => setAccountPopup(true)}>編集</Button>
									<Button variant="danger">削除</Button>
								</div>
							</Col>
						</Row>
						<Row className="mb-4">
							<Col bsPrefix="col-1">
								<input type="checkbox" name="checked" className="form-check-input" />
							</Col>
							<Col bsPrefix="col-1">
								<span>No.xxx</span>
							</Col>
							<Col bsPrefix="col-2">
								<span>テスト中山</span>
							</Col>
							<Col bsPrefix="col-6">
								<span>xxxx@financial-agency.com</span>
							</Col>
							<Col bsPrefix="col-2">
								<div className="d-flex justify-content-end gap-3">
									<Button variant="primary" onClick={() => setAccountPopup(true)}>編集</Button>
									<Button variant="danger">削除</Button>
								</div>
							</Col>
						</Row>
						<Row className="mb-4">
							<Col bsPrefix="col-1">
								<input type="checkbox" name="checked" className="form-check-input" />
							</Col>
							<Col bsPrefix="col-1">
								<span>No.xxx</span>
							</Col>
							<Col bsPrefix="col-2">
								<span>テスト中山</span>
							</Col>
							<Col bsPrefix="col-6">
								<span>xxxx@financial-agency.com</span>
							</Col>
							<Col bsPrefix="col-2">
								<div className="d-flex justify-content-end gap-3">
									<Button variant="primary" onClick={() => setAccountPopup(true)}>編集</Button>
									<Button variant="danger">削除</Button>
								</div>
							</Col>
						</Row>
						<Row className="mb-4">
							<Col bsPrefix="col-1">
								<input type="checkbox" name="checked" className="form-check-input" />
							</Col>
							<Col bsPrefix="col-1">
								<span>No.xxx</span>
							</Col>
							<Col bsPrefix="col-2">
								<span>テスト中山</span>
							</Col>
							<Col bsPrefix="col-6">
								<span>xxxx@financial-agency.com</span>
							</Col>
							<Col bsPrefix="col-2">
								<div className="d-flex justify-content-end gap-3">
									<Button variant="primary" onClick={() => setAccountPopup(true)}>編集</Button>
									<Button variant="danger">削除</Button>
								</div>
							</Col>
						</Row>
						<Row className="mb-4">
							<Col bsPrefix="col-1">
								<input type="checkbox" name="checked" className="form-check-input" />
							</Col>
							<Col bsPrefix="col-1">
								<span>No.xxx</span>
							</Col>
							<Col bsPrefix="col-2">
								<span>テスト中山</span>
							</Col>
							<Col bsPrefix="col-6">
								<span>xxxx@financial-agency.com</span>
							</Col>
							<Col bsPrefix="col-2">
								<div className="d-flex justify-content-end gap-3">
									<Button variant="primary" onClick={() => setAccountPopup(true)}>編集</Button>
									<Button variant="danger">削除</Button>
								</div>
							</Col>
						</Row>
						<Row className="mb-4">
							<Col bsPrefix="col-1">
								<input type="checkbox" name="checked" className="form-check-input" />
							</Col>
							<Col bsPrefix="col-1">
								<span>No.xxx</span>
							</Col>
							<Col bsPrefix="col-2">
								<span>テスト中山</span>
							</Col>
							<Col bsPrefix="col-6">
								<span>xxxx@financial-agency.com</span>
							</Col>
							<Col bsPrefix="col-2">
								<div className="d-flex justify-content-end gap-3">
									<Button variant="primary" onClick={() => setAccountPopup(true)}>編集</Button>
									<Button variant="danger">削除</Button>
								</div>
							</Col>
						</Row>
						<Row className="mb-4">
							<Col bsPrefix="col-1">
								<input type="checkbox" name="checked" className="form-check-input" />
							</Col>
							<Col bsPrefix="col-1">
								<span>No.xxx</span>
							</Col>
							<Col bsPrefix="col-2">
								<span>テスト中山</span>
							</Col>
							<Col bsPrefix="col-6">
								<span>xxxx@financial-agency.com</span>
							</Col>
							<Col bsPrefix="col-2">
								<div className="d-flex justify-content-end gap-3">
									<Button variant="primary" onClick={() => setAccountPopup(true)}>編集</Button>
									<Button variant="danger">削除</Button>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
			</Stack>
		</WrapperDefaultLayout>
			{
				accountPopup ?
					<div css={modalWrap}>
						<span onClick={() => setAccountPopup(false)} css={closedBtn}>
							<span></span>
							<span></span>
						</span>
						<AccountPopup />
					</div>
					: ""
			}
		</>
	);
});

const modalWrap = css`
	position: fixed;
	top: 50%;
	left: 50%;
	max-width: 500px;
	width: 92%;
	transform: translate(-50%,-50%);
`
const closedBtn = css`
	width: 40px;
	height: 40px;
	position: absolute;
	top: -10px;
	right: -10px;
	background: #6c757d;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	span{
		position: absolute;
		width: 10px;
		height: 2px;
		background: #fff;
		&:first-of-type{
			transform: rotate(45deg);
		}
		&:last-of-type{
			transform: rotate(-45deg);
		}
	}
`